import axios from "axios";
import { $apiV2, VIDEO_SERVICE_URL } from "../config/api";

export function groupByNestedProperty(array, propertyPath) {
  return array.reduce((acc, obj) => {
    const key = propertyPath.split('.').reduce((o, p) => o && o[p], obj);
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
}

export const getUniqueArrayObjectsByKey = (array, key) => {
  return Array.from(
    array.reduce((map, current) => {
      if (!map.has(current[key])) {
        map.set(current[key], current);
      }
      return map;
    }, new Map()).values()
  )
}

export const findMaxSecondMarker = (data) => {
  let maxSecond = 5400;

  data.forEach(item => {
    const second = parseFloat(item.second);
    if (second > maxSecond) {
      maxSecond = second;
    }
  });

  return maxSecond;
}

export const getTeamParamMarkers = async (
  team_id,
  param_id,
  type = 'in_attack',
  season = '',
  controller,
  page = 1,
  prevResponse = {},
) => {
  try {
    let data = prevResponse;
    const response = await $apiV2.get(
      `/platform/team/${team_id}/overview/${type}?page=${page}&param_team_id=${param_id}&year_season=${season}`,
      { signal: controller?.signal }
    );
    data = {
      ...response.data,
      results: data?.results?.length ? [...data.results, ...response.data?.results] : response.data?.results,
    };
    if (response.data?.next) {
      return getTeamParamMarkers(team_id, param_id, type, season, controller, page + 1, data);
    }
    return Promise.resolve(data);
  } catch (e) {
    if (axios.isCancel(e)) {
      return Promise.reject({ type: 'canceled', message: `Requset was canceled` });
    }
    return Promise.reject({ type: 'error', message: e.response || e.message });
  }
};

export const getTeamAttackVideos = async (
  team_id,
  param_id,
  season = '',
  page = 1,
  prevResponse = {},
) => {
  try {
    let data = prevResponse;
    const response = await $apiV2.get(`/platform/team/${team_id}/overview/in_attack?page=${page}&param_team_id=${param_id}&year_season_id=${season}`);
    data = {
      ...response.data,
      results: data?.results?.length ? [...data.results, ...response.data?.results] : response.data?.results,
    };
    if (response.data?.next) {
      return getTeamAttackVideos(team_id, param_id, season, page + 1, data);
    }
    return Promise.resolve(data);
  } catch (e) {
    return Promise.reject({ type: 'error', message: e.response || e.message });
  }
};

export const getTeamDefenseVideos = async (
  team_id,
  param_id,
  season = '',
  page = 1,
  prevResponse = {},
) => {
  try {
    let data = prevResponse;
    const response = await $apiV2.get(`/platform/team/${team_id}/overview/in_defence?page=${page}&param_team_id=${param_id}&year_season_id=${season}`);
    data = {
      ...response.data,
      results: data?.results?.length ? [...data.results, ...response.data?.results] : response.data?.results,
    };
    if (response.data?.next) {
      return getTeamDefenseVideos(team_id, param_id, season, page + 1, data);
    }
    return Promise.resolve(data);
  } catch (e) {
    return Promise.reject({ type: 'error', message: e.response || e.message });
  }
};

export const getPlayerParamMarkers = async (
  player_id,
  param_id,
  season = '',
  controller,
  page = 1,
  prevResponse = {}
) => {
  try {
    let data = prevResponse;
    const response = await $apiV2.get(
      `/platform/player/${player_id}/markers?page=${page}&param_id=${param_id}&year_season_id=${season}`,
      { signal: controller?.signal }
    );
    data = {
      ...response.data,
      results: data?.results?.length ? [...data.results, ...response.data?.results] : response.data?.results,
    };
    if (response.data?.next) {
      return getPlayerParamMarkers(player_id, param_id, season, controller, page + 1, data);
    }
    return Promise.resolve(data);
  } catch (e) {
    if (axios.isCancel(e)) {
      return Promise.reject({ type: 'canceled', message: `Requset was canceled` });
    }
    return Promise.reject({ type: 'error', message: e.response || e.message });
  }
};


export const getGroupedAndSortedParametrs = (parametrs, withoutGroup = false) => {
  try {
    const groupedParams = parametrs?.sort((a, b) => {
      if (a.group === null) {
        return 1;
      }
      if (b.group === null) {
        return -1;
      }
      return a.group.id < b.group.id ? -1 : 1;
    })
      .reduce((group, item) => {
        if (item.group) {
          if (!group[item.group.name]) {
            group[item.group.name] = [];
          }
          group[item.group.name].push({ ...item, checked: true });
          group[item.group.name] = group[item.group.name]
            .sort((a, b) => (a.sort_order < b.sort_order ? -1 : 1))
          return group;
        }

        // uncomment if you need parameters without a group
        if (withoutGroup) {
          if (!group['Without group']) {
            group['Without group'] = []
          }
          group['Without group'].push({ ...item, checked: true });
          group['Without group'] = group['Without group']
            .sort((a, b) => (a.name < b.name ? -1 : 1))
        }

        return group;
      }, {});
    return Object.entries(groupedParams);
  } catch {
    return [];
  }
}

export function extractFirstCharacters(str) {
  if (!str) return ''
  const tmp = str.replace('-', ' - ')
  const words = tmp.split(' ');

  const firstCharacters = words.map((word) => word.charAt(0));

  const result = firstCharacters.join('');

  return result;
}


export function mirrorCoordinates(marker) {
  try {
    let result = { ...marker }
    if (+marker?.coord_x >= 52.5) {
      const coord_x = Math.abs(105 - +marker.coord_x)
      const coord_y = Math.abs(68 - +marker.coord_y)
      result = {
        ...result,
        coord_x,
        coord_y
      }
    }
    if (+marker?.coord_x_destination >= 52.5) {
      const coord_x_destination = Math.abs(105 - +marker.coord_x_destination)
      const coord_y_destination = Math.abs(68 - +marker.coord_y_destination)
      result = {
        ...result,
        coord_x_destination,
        coord_y_destination
      }
    }
    return result;
  } catch (e) {
    console.log('error mirror coordinates marker', e);
    return marker
  }
}

export function mirrorCoordinatesMarkers(markers) {
  try {
    const new_markers = markers.map(marker => {
      const cond = +marker?.coord_x >= 52.5
      if (cond) {
        return (mirrorCoordinates(marker))
      } else {
        return marker
      }
    })

    return new_markers;
  } catch (e) {
    console.log('mirror coordinates err', e)
    return markers;
  }
}

export const combineCuts = async (params) => {
  const result = await axios.post(
    `${VIDEO_SERVICE_URL}/cut_many_video/?out=file`,
    params,
  );

  return result.data
}
