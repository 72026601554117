import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
    AdditionalActions,
    BottomControlsContainer,
    CenterControls,
    Divider,
    LeftControls,
    MainControlsContainer,
    RightControls,
    TimeLineWrapper,
    VideoControlsWrapper,
    VolumeTooltip,
    VolumeControl,
    SpeedWrapper,
} from './VideoControls';

// icons
import { ReactComponent as PlayIcon } from './icons/ic_play.svg';
import { ReactComponent as PauseIcon } from './icons/pause.svg';
import { ReactComponent as Minus5Icon } from './icons/ic_-5.svg';
import { ReactComponent as Plus5Icon } from './icons/ic_+5.svg';
import { ReactComponent as PrevIcon } from './icons/ic_prev.svg';
import { ReactComponent as NextIcon } from './icons/ic_next.svg';
import { ReactComponent as SettingsIcon } from './icons/ic_settings2.svg';
import { ReactComponent as FullScreenIcon } from './icons/fullscreen-line.svg';
import { ReactComponent as ScreenShotIcon } from './icons/ic_capture.svg';
import { ReactComponent as RecordIcon } from './icons/record-button.svg';
import { ReactComponent as MutedIcon } from './icons/ic_mute.svg';
import { ReactComponent as VolumeIcon } from './icons/ic_volume.svg';
import { IconWrapper } from './icons/IconWrapper';
import TextInput from '../../../../components/inputs/TextInput';
import { Input } from './Input';
import { Oval } from 'react-loader-spinner';
import VideoDownloader from './VideoDownloader';
import { downloadVideosActions } from '../../../../store/download_videos/downloadVideosSlice';
import { useDispatch } from 'react-redux';
import { useNotification } from '../../../../components/notification/useNotification';
import { NotificationList } from '../../../../components/notification/NotificationList';

const ChangeSpeed = ({ speed, onSpeedChange }) => {
    const speedLevel = useMemo(() => {
        return `${speed}x`
    }, [speed])
    

    return (
        <VolumeControl>
            <SpeedWrapper>
                <span>{speedLevel}</span>
            </SpeedWrapper>
            <VolumeTooltip>
                <input
                    type="range"
                    id="volume-slider"
                    min={0.25}
                    max={4}
                    step='0.25'
                    value={speed}
                    onInput={(e) => onSpeedChange(e.target.value)}
                />
                <span id="volume-level">{speedLevel}</span>
            </VolumeTooltip>
        </VolumeControl>
    )
}

const ChangeVolume = ({ volume, onVolumeChange }) => {
    const volumeLevel = useMemo(() => {
        return `${(+volume * 100).toFixed(0)}%`
    }, [volume])

    return (
        <VolumeControl>
            <IconWrapper
            //  onClick={() => onVolumeChange(volume === 0 ? 1 : 0)} // need fix for mobile
            >
                {volume !== 0 ? (
                    <VolumeIcon width={23} height={20} />
                ) : (
                    <MutedIcon width={20} height={20} />
                )}
            </IconWrapper>
            <VolumeTooltip>
                <input
                    type="range"
                    id="volume-slider"
                    min="0"
                    max="1"
                    step="0.1"
                    value={volume}
                    onInput={(e) => onVolumeChange(e.target.value)}
                />
                <span id="volume-level">{volumeLevel}</span>
            </VolumeTooltip>
        </VolumeControl>
    )
}

const VideoSettings = (props) => {
    const { offsets, changeOffSets } = props;
    return (
        <VolumeControl>
            <IconWrapper >
                <SettingsIcon />
            </IconWrapper>
            <VolumeTooltip style={{ top: '-70px', left: '-150px' }}>
                <Input
                    value={offsets?.start}
                    type='number'
                    min={1}
                    placeholder='Start offset'
                    label={'Start offset'}
                    onChange={(e) => changeOffSets(e.target.value, offsets?.end)}
                />
                <Input
                    value={offsets?.end}
                    onChange={(e) => changeOffSets(offsets?.start, e.target.value)}
                    type='number'
                    min={1}
                    placeholder='End offset'
                    label={'End offset'}
                />
            </VolumeTooltip>
        </VolumeControl>
    )
}

export const VideoPlayer = (props) => {
    const {
        video: videoLink,
        offsets,
        changeOffSets,
        videos,
        loading,
        addRecordToPlaylist,
        recordTime,
        setRecordTime,
        playNextVideo,
        playPrevVideo,
        disabledNext,
        disabledPrev,
        needDownload = false,
        styleWrapper = {},
    } = props;
    const dispatch = useDispatch();

    const { addNotification, removeNotification, notifications } = useNotification();



    const videoRef = useRef(null);
    const videoContainer = useRef(null);
    const playPauseBtn = useRef(null);
    const totalTimeElem = useRef(null);
    const currentTimeElem = useRef(null);
    const timelineContainer = useRef(null);
    const controlsRef = useRef(null);
    const pausedOverlay = useRef(null);

    const wasPaused = useRef(true);
    const isScrubbing = useRef(false);

    const [nextVideo, setNextVideo] = useState(null);
    const [indexNext, setIndexNext] = useState(0);
    const [indexCurrent, setIndexCurrent] = useState(0);

    const [playbackRate, setPlaybackRate] = useState(1);
    const [volume, setVolume] = useState(0);

    const [paused, setPaused] = useState(false);
    const [fullScreen, setFullScreen] = useState(false);

    const startMarker = useRef(null);
    const endMarker = useRef(null);
    // const [isDraggingStart, setIsDraggingStart] = useState(false);
    // const [isDraggingEnd, setIsDraggingEnd] = useState(false);

    const isAuth = localStorage.getItem('token');

    useEffect(() => {
        const handleLoadedData = () => {
            if (totalTimeElem?.current) {
                totalTimeElem.current.textContent = formatDuration(
                    videoRef.current?.duration || 0,
                );
            }
            setPaused(true);
            videoRef.current?.play();
        };

        const handleVideoTimeUpdate = () => {
            if (currentTimeElem.current && timelineContainer.current) {
                currentTimeElem.current.textContent = `${formatDuration(
                    videoRef.current?.currentTime || 0,
                )} / ${formatDuration(
                    videoRef.current?.duration || 0,
                )}`;
                const percent = videoRef.current
                    ? // eslint-disable-next-line no-unsafe-optional-chaining
                    videoRef.current?.currentTime / videoRef.current?.duration
                    : 0;
                timelineContainer.current.style.setProperty(
                    '--progress-position',
                    percent.toString(),
                );
            }
        };

        const handleAddClassToVideoContainer = () => {
            videoContainer.current?.classList.add('paused');
            pausedOverlay.current?.classList.add('active');
        };

        const handleRemoveClassToVideoContainer = () => {
            videoContainer.current?.classList.remove('paused');
            pausedOverlay.current?.classList.remove('active');
        };

        const handleMouseUpFromTimeLine = (e) => {
            if (isScrubbing.current) toggleScrubbing(e);
        };

        playPauseBtn.current?.addEventListener('click', togglePlay);

        videoRef.current?.addEventListener('click', togglePlay);
        videoRef.current?.addEventListener('loadeddata', handleLoadedData);
        videoRef.current?.addEventListener('timeupdate', handleVideoTimeUpdate);
        videoRef.current?.addEventListener(
            'play',
            handleRemoveClassToVideoContainer,
        );
        videoRef.current?.addEventListener('pause', handleAddClassToVideoContainer);
        videoRef.current?.addEventListener('volumechange', onVolumeChange);
        videoRef.current?.addEventListener('ratechange', onSpeedChange);

        document.addEventListener('keydown', handleKeyDown);

        timelineContainer.current?.addEventListener('mousedown', toggleScrubbing);
        document.addEventListener('mouseup', handleMouseUpFromTimeLine);
        document.addEventListener('fullscreenchange', () => {
            setFullScreen(Boolean(document?.fullscreenElement))
            videoContainer?.current?.classList.toggle(
                'full-screen',
                document.fullscreenElement,
            );
        });

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            playPauseBtn.current?.removeEventListener('click', togglePlay);
            videoRef.current?.removeEventListener('click', togglePlay);
            videoRef.current?.removeEventListener('loadeddata', handleLoadedData);
            videoRef.current?.removeEventListener(
                'timeupdate',
                handleVideoTimeUpdate,
            );
            videoRef.current?.removeEventListener(
                'play',
                handleRemoveClassToVideoContainer,
            );
            videoRef.current?.removeEventListener(
                'pause',
                handleAddClassToVideoContainer,
            );
            // eslint-disable-next-line react-hooks/exhaustive-deps
            videoRef.current?.removeEventListener('volumechange', onVolumeChange);
            // eslint-disable-next-line react-hooks/exhaustive-deps
            videoRef.current?.removeEventListener('ratechange', onSpeedChange);
            // eslint-disable-next-line react-hooks/exhaustive-deps
            timelineContainer.current?.removeEventListener(
                'mousedown',
                toggleScrubbing,
            );

            document.removeEventListener('keydown', handleKeyDown);
            document.removeEventListener('mouseup', handleMouseUpFromTimeLine);
            document.removeEventListener('fullscreenchange', () => {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                setFullScreen(Boolean(document?.fullscreenElement))
                videoContainer?.current?.classList.toggle(
                    'full-screen',
                    document.fullscreenElement,
                );
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleKeyDown = (e) => {
        const tagName = document?.activeElement?.tagName.toLowerCase();

        if (tagName === 'input') return;
        switch (e.key.toLowerCase()) {
            case ' ':
                if (tagName === 'button') return;
                togglePlay();
                break;
            case 'f':
                toggleFullScreenMode();
                break;
            case 'arrowleft':
            case 'j':
                skip(-1);
                break;
            case 'arrowright':
            case 'l':
                skip(1);
                break;
            default:
                break;
        }
    };

    function fetchFile(url) {
        const tempUrl = url;
        const aTag = document.createElement('a');
        aTag.href = tempUrl;
        // eslint-disable-next-line
        aTag.download = url.replace(/^.*[\\\/]/, '');
        document.body.appendChild(aTag);
        aTag.click();
        URL.revokeObjectURL(tempUrl);
        aTag.remove();
    }

    const formatDuration = (time) => {
        const leadingZeroFormatter = new Intl.NumberFormat(undefined, {
            minimumIntegerDigits: 2,
        });
        const seconds = Math.floor(time % 60);
        const minutes = Math.floor(time / 60) % 60;
        const hours = Math.floor(time / 3600);
        if (hours === 0) {
            return `${minutes}:${leadingZeroFormatter.format(seconds)}`;
        }
        return `${hours}:${leadingZeroFormatter.format(
            minutes,
        )}:${leadingZeroFormatter.format(seconds)}`;
    };

    function skip(duration) {
        if (videoRef.current) {
            videoRef.current.currentTime += duration;
        }
    }

    function launchIntoFullscreen(element) {
        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) {
            element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) {
            element.msRequestFullscreen();
        } else {
            element.classList.toggle('fullscreen');
        }
    }

    function exitFullscreen() {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }
    }

    function toggleFullScreenMode() {
        const fullscreenElement = document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || document.msFullscreenElement;

        if (fullscreenElement) {
            exitFullscreen();
        } else {
            launchIntoFullscreen(videoContainer.current);
        }
    }

    const togglePlay = () => {
        wasPaused.current = !videoRef.current?.paused;
        videoRef.current?.paused
            ? videoRef.current?.play()
            : videoRef.current?.pause();
        setPaused(!videoRef.current?.paused);
    };

    const handleChangeSpeed = (value) => {
        if (videoRef.current) {
            videoRef.current.playbackRate = +value;
        }
    };

    function onVolumeChange(e) {
        setVolume(+videoRef.current?.volume);
    }

    function onSpeedChange() {
        setPlaybackRate(+videoRef.current?.playbackRate)
    }

    function toggleScrubbing(e) {
        const rect = timelineContainer.current?.getBoundingClientRect();
        if (rect && videoRef.current) {
            const percent =
                Math.min(Math.max(0, e.x - rect.x), rect.width) / rect.width;
            // eslint-disable-next-line no-bitwise
            isScrubbing.current = (e.buttons & 1) === 1;
            // videoContainer.current.classList.toggle('scrubbing', isScrubbing.current);
            if (isScrubbing.current) {
                wasPaused.current = videoRef.current?.paused;
                videoRef.current?.pause();
            } else {
                videoRef.current.currentTime = percent * videoRef.current.duration;
                if (!wasPaused.current) videoRef.current.play();
            }
        }

        handleTimelineUpdate(e);
    }

    function handleTimelineUpdate(e) {
        if (timelineContainer.current && videoRef.current) {
            const rect = timelineContainer.current.getBoundingClientRect();
            const percent =
                Math.min(Math.max(0, e.x - rect.x), rect.width) / rect.width;

            if (isScrubbing) {
                e.preventDefault();
                timelineContainer.current.style.setProperty(
                    '--progress-position',
                    percent.toString(),
                );
            }
        }
    }

    const handleChangeVolume = (value) => {
        if (videoRef.current) {
            videoRef.current.volume = +value;
            videoRef.current.muted = +value === 0;
        }
    };

    const handleScreenshot = () => {
        const canvas = document.createElement('canvas');
        canvas.width = videoRef.current?.videoWidth;
        canvas.height = videoRef.current?.videoHeight;

        if (videoRef.current) {
            const context = canvas.getContext('2d');
            context.drawImage(
                videoRef.current,
                0,
                0,
                videoRef.current?.videoWidth,
                videoRef.current?.videoHeight,
            );
        }
        const dataURL = canvas.toDataURL('image/jpeg');

        const a = document.createElement('a');
        a.href = dataURL;
        a.download = `Screenshot_${videoRef.current?.currentTime}_match_${videos?.[indexCurrent]?.title}.jpeg`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const handleChangeSecond = (second) => {
        videoRef.current.currentTime += second;
    };

    const handleRecord = async (second) => {
        if (!recordTime?.startTime) {
            setRecordTime({ startTime: second });
        } else if (recordTime?.startTime && recordTime.startTime < second) {
            setRecordTime((prev) => ({ ...prev, endTime: second }));
            !paused && togglePlay()
            //   setAddPlaylistOpenModal(true)
            // await handleSavePlaylist();
        }
    };

    // test functionality for moving record markers
    // function startDragStart(e) {
    //     console.log('start drag start')
    //     setIsDraggingStart(true);
    // }

    // function startDragEnd(e) {
    //     console.log('start drag end')
    //     setIsDraggingEnd(true);
    // }

    // function drag(e) {
    //     if (!recordTime || (!isDraggingEnd && !isDraggingStart)) return;
    //     const rect = timelineContainer.current.getBoundingClientRect();
    //     if (isDraggingStart) {
    //         const newLeft = Math.min(Math.max(e.clientX - rect.left, 0), rect.width - startMarker.current?.offsetWidth || 0);
    //         const endLeft = parseInt(endMarker.current.style.left);
    //         console.log('can move', newLeft / rect.width < endLeft, newLeft, endLeft, newLeft / rect.width * 100)
    //         if (((newLeft / rect.width * 100) + 8 ) < endLeft) {
    //             const time = (newLeft / rect.width) * videoRef.current.duration;
    //             setRecordTime((prev) => ({ ...prev, startTime: time }));
    //             videoRef.current.currentTime = time;
    //         }
    //     }

    //     if (isDraggingEnd) {
    //         const newLeft = Math.min(Math.max(e.clientX - rect.left, 0), rect.width - endMarker.current?.offsetWidth);
    //         const startLeft = parseInt(startMarker.current.style.left);
    //         if (((newLeft / rect.width * 100) - 8) > startLeft) {
    //             const time = (newLeft / rect.width) * videoRef.current.duration;
    //             setRecordTime((prev) => ({ ...prev, endTime: time }));
    //             videoRef.current.currentTime = time;
    //         }
    //     }
    // }

    // function stopDrag() {
    //     setIsDraggingStart(false);
    //     setIsDraggingEnd(false);
    // }

    return (
        <>
            <div
                // onMouseMove={drag} 
                // onMouseUp={stopDrag}
                ref={videoContainer}
                style={{ ...styleWrapper, position: 'relative', lineHeight: 0 }}
            >
                <video
                    ref={videoRef}
                    src={videoLink}
                    id='video'
                    onEnded={() => {
                        if (!disabledNext) {
                            playNextVideo();
                        }
                    }}
                    autoPlay
                    // muted={timelineContainer.current > 0}
                    muted
                    playsInline
                    crossOrigin='anonymous'
                    style={{
                        width: '100%',
                        borderRadius: '5px 5px 0 0',
                        background: '#04080287',
                        height: fullScreen ? `calc(100% - ${controlsRef.current?.clientHeight || 119}px)` : 'auto',
                        maxHeight: fullScreen ? `max-content` : '70vh'
                    }}
                />
                {loading && (
                    <Oval
                        height="80"
                        width="80"
                        color={localStorage.getItem("theme") === "light" ? "#2363F7" : "#CFDAE6"}
                        ariaLabel="oval-loading"
                        wrapperStyle={{
                            display: 'flex',
                            justifyContent: 'center',
                            position: 'absolute',
                            top: '30%',
                            left: '45%',
                        }}
                        wrapperClass=""
                    />
                )}
                <VideoControlsWrapper
                    ref={controlsRef}
                    style={{ opacity: videoLink ? 1 : 0.5, pointerEvents: videoLink ? 'auto' : 'none' }}
                >
                    <TimeLineWrapper>
                        <div className='timeline-container' ref={timelineContainer}>
                            <div className='timeline'>
                                <div className='thumb-indicator'></div>
                                {Boolean(recordTime?.startTime) && (
                                    <>
                                        <div
                                            className='record-point__timeline'
                                            style={{
                                                left: `${(recordTime.startTime / videoRef.current?.duration) * 100
                                                    }%`,
                                                width: `calc(${(((recordTime.endTime ?? videoRef.current?.currentTime) -
                                                    recordTime.startTime) /
                                                    videoRef.current?.duration) *
                                                    100
                                                    }%)`,
                                            }}
                                        />
                                        <div
                                            className='record-point'
                                            ref={startMarker}
                                            // onMouseDown={startDragStart}
                                            style={{
                                                left: `${(recordTime.startTime / videoRef.current?.duration) * 100
                                                    }%`,
                                            }}
                                        >
                                            <div className='record-point__time'>
                                                {formatDuration(recordTime.startTime)}
                                            </div>
                                            <RecordIcon width={35} height={35} />
                                        </div>
                                    </>
                                )}
                                {Boolean(recordTime?.endTime) && (
                                    <div
                                        className='record-point'
                                        // onMouseDown={startDragEnd}
                                        ref={endMarker}
                                        style={{
                                            left: `${(recordTime.endTime / videoRef.current?.duration) * 100
                                                }%`,
                                        }}
                                    >
                                        <div className='record-point__time'>
                                            {formatDuration(recordTime.endTime)}
                                        </div>
                                        <RecordIcon width={35} height={35} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </TimeLineWrapper>
                    <BottomControlsContainer>
                        <MainControlsContainer>
                            <LeftControls>
                                {!paused ? (
                                    <IconWrapper onClick={togglePlay}>
                                        <PlayIcon />
                                    </IconWrapper>
                                ) : (
                                    <IconWrapper onClick={togglePlay}>
                                        <PauseIcon width={20} height={20} />
                                    </IconWrapper>
                                )}
                                <IconWrapper onClick={() => handleChangeSecond(-5)}>
                                    <Minus5Icon />
                                </IconWrapper>
                                <IconWrapper onClick={() => handleChangeSecond(5)}>
                                    <Plus5Icon />
                                </IconWrapper>
                                <ChangeVolume onVolumeChange={handleChangeVolume} volume={volume} />
                            </LeftControls>
                            <CenterControls>
                                <IconWrapper
                                    style={{
                                        opacity: !disabledPrev ? 1 : 0.5,
                                        pointerEvents: !disabledPrev ? 'auto' : 'none',
                                    }}
                                    onClick={playPrevVideo}
                                >
                                    <PrevIcon />
                                </IconWrapper>
                                <div ref={currentTimeElem} className='current-time'>
                                    0:00 / 0:00
                                </div>
                                <IconWrapper
                                    style={{
                                        opacity: !disabledNext ? 1 : 0.5,
                                        pointerEvents: !disabledNext ? 'auto' : 'none',
                                    }}
                                    onClick={playNextVideo}
                                >
                                    <NextIcon />
                                </IconWrapper>
                            </CenterControls>

                            <RightControls>
                                <ChangeSpeed speed={playbackRate} onSpeedChange={handleChangeSpeed} />
                                <VideoSettings offsets={offsets} changeOffSets={changeOffSets} />
                                <IconWrapper onClick={toggleFullScreenMode}>
                                    <FullScreenIcon width={20} />
                                </IconWrapper>
                            </RightControls>
                        </MainControlsContainer>
                    </BottomControlsContainer>
                    <Divider />
                    <AdditionalActions>
                        <div onClick={handleScreenshot}>
                            <IconWrapper >
                                <ScreenShotIcon />
                            </IconWrapper>
                            Take Screenshot
                        </div>
                        {isAuth && (
                            <>
                                <div
                                    style={{
                                        opacity: videos?.length > 0 ? 1 : 0.5,
                                        pointerEvents: videos?.length > 0 ? 'auto' : 'none',
                                    }}
                                    onClick={() => handleRecord(videoRef.current?.currentTime ?? 0)}>
                                    <RecordIcon width={35} height={35} /> Record
                                </div>
                                <div
                                    style={{
                                        opacity: recordTime?.startTime && recordTime?.endTime ? 1 : 0.5,
                                        pointerEvents: recordTime?.startTime && recordTime?.endTime ? 'auto' : 'none',
                                    }}
                                    onClick={addRecordToPlaylist}
                                >
                                    Add Record To Playlist
                                </div>
                                <div
                                    onClick={() => setRecordTime({ startTime: 0, endTime: 0 })}
                                    style={{
                                        opacity: recordTime?.startTime || recordTime?.endTime ? 1 : 0.5,
                                        pointerEvents: recordTime?.startTime || recordTime?.endTime ? 'auto' : 'none',
                                    }}
                                >
                                    Remove Record
                                </div>
                            </>
                        )}
                        {/* add needDownload prop if need hide this button for special pages  */}
                        {videoLink && (
                            <div
                                onClick={() => {
                                    dispatch(downloadVideosActions.addVideoLink(videoLink))
                                    addNotification('Please do not close the tab while the download is in progress.', 'info')
                                }}
                            >
                                Download Video
                            </div>
                        )}
                    </AdditionalActions>
                </VideoControlsWrapper>
            </div>
            {/* display notifications */}
            <NotificationList
                notifications={notifications}
                onClose={removeNotification}
            />
        </>

    )
}
