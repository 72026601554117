import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../../../components/breadcrumbs";
import IntroText from "../../../components/introText/index";
import Navigation from "../../../components/Navigation/Navigation";
import { Oval } from "react-loader-spinner";

// Style
import { LoadingWrapper } from "../../../style/Global";
import {
  LeftInfo,
  RightInfo,
  TopCard,
  TopContent,
} from "../../../style/Competition";
import {
  TabContent,
  TabListWrapper,
  TabsWrapper,
  TabTitle,
  TopList,
} from "../../../style/Favorites";
import {
  MainWrapper,
  TopWrapper,
  MainWrapperFavorite,
} from "../../../style/DasboardMain";

// Images
import placeholder from "../../../images/ic_logo.svg";

// Components
import CompetitionMatch from "../../../components/table/CompetitionMatch";
import BestPlayers from "../../../components/table/BestPlayers";
import BestTeams from "../../../components/table/BestTeams";
import TeamsTable from "../../../components/table/TeamsTable";
import { $apiV1, $apiV2 } from "../../../config/api";
import { getGroupedAndSortedParametrs } from "../../../helpers";

const Index = () => {
  const [loading, setLoading] = useState(false);
  const [loadingInfo, setLoadingInfo] = useState(false);
  const [info, setInfo] = useState("");
  const [bestPlayersInfo, setBestPlayersInfo] = useState();
  const [bestTeamsInfo, setBestTeamsInfo] = useState();

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [teamParams, setTeamParams] = useState([])

  const { id } = useParams();

  const breadcrumbs = [
    {
      title: "Competitions",
      link: "/tournaments",
    },
    {
      title: `${info?.title}`,
    },
  ];

  useEffect(() => {
    const getTeamParams = async () => {
      try {
        let params = null;
        if (sessionStorage.getItem('teamParameters')) {
          params = JSON.parse(sessionStorage.getItem('teamParameters'))
        } else {
          const response = await $apiV1.get('/stats/parameters/team/');
          const data = response.data?.results;
          if (data) {
            params = getGroupedAndSortedParametrs(data);
          }
        }
        console.log('params', params)
        setTeamParams(params);
      } catch (e) { /* empty */ }
    }

    getTeamParams();
  }, [])

  const getMathesStat = async (
    match,
    params = JSON.parse(sessionStorage.getItem('teamParameters')) || [],
    idx,
  ) => {
    try {
      let allParams = []
      params.forEach(([_, value]) => {
        allParams = [...allParams, ...value]
      })
      allParams = allParams?.filter((param) => param.checked)
      const checkedParamsIds = allParams?.map((item) => item.id);
      const response = await $apiV1.get(`/stats/match_teams/${match.id}?params=${checkedParamsIds?.join(',')}`);
      return {
        ...match,
        home_team: {
          ...response.data.teams_stats.home_team,
          stats: response.data.teams_stats.home_team?.stats?.sort((a, b) => a.parameter_id - b.parameter_id)
        },
        away_team: {
          ...response.data.teams_stats.away_team,
          stats: response.data.teams_stats.away_team?.stats?.sort((a, b) => a.parameter_id - b.parameter_id)
        },
        idx
      };
    } catch (e) {
      console.log('get match stat err', e);
      return Promise.reject(match);
    } finally {
    }
  }


  const getInfo = async (id, page = 1) => {
    try {
      setLoadingInfo(true);
      const response = await $apiV1.get(`/statistics/season/${id}`, {
        params: { page },
      });
      let data = { ...response.data };
      if (data?.matches?.length > 0) {
        return Promise
          .allSettled(data.matches.map((match, idx) => getMathesStat(match, teamParams, idx)))
          .then(res => {
            res.map(resPromise => {
              if (resPromise.status === 'fulfilled') {
                data.matches[resPromise.value.idx] = resPromise.value;
              }
            })
          })
          .finally(() => {
            setInfo(data);
            setTotalPages(response?.data?.pages_total || 1);
            setLoadingInfo(false);
          });
      }
      setInfo(data);
      setTotalPages(response?.data?.pages_total || 1);
      setLoadingInfo(false);
    } catch (error) {
      console.error("Error fetching players:", error);
    } finally {
    }
  };

  const getBestPlayers = async (id) => {
    try {
      setLoading(true);
      const response = await $apiV2.get(`/platform/season/${id}/best_players`);
      setBestPlayersInfo(response?.data);
    } catch (error) {
      console.error("Error fetching players:", error);
    } finally {
      setLoading(false);
    }
  };

  const getBestTeams = async (id) => {
    try {
      setLoading(true);
      const response = await $apiV2.get(`/platform/season/${id}/best_teams`);
      setBestTeamsInfo(response?.data);
    } catch (error) {
      console.error("Error fetching players:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getInfo(id, currentPage);
    getBestPlayers(id);
    getBestTeams(id);
  }, [id, currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const teamsTable = [
    {
      id: 1,
      teamName: "Mostar Velez",
      p: 12,
      wins: 5,
      draw: 0,
      lose: 12,
      goals: "4:1",
      points: 29,
    },
    {
      id: 2,
      teamName: "Mostar Velez",
      p: 12,
      wins: 5,
      draw: 0,
      lose: 12,
      goals: "4:1",
      points: 29,
    },
    {
      id: 3,
      teamName: "Mostar Velez",
      p: 12,
      wins: 5,
      draw: 0,
      lose: 12,
      goals: "4:1",
      points: 29,
    },
  ];

  const [navigationOpen, setNavigationOpen] = useState(
    localStorage.getItem("navigationOpen")
  );

  useEffect(() => {
    const handleNavigationOpenChange = () => {
      setNavigationOpen(localStorage.getItem("navigationOpen"));
      console.log(localStorage.getItem("navigationOpen"), "ss");
    };

    window.addEventListener("navigationOpenChange", handleNavigationOpenChange);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener(
        "navigationOpenChange",
        handleNavigationOpenChange
      );
    };
  }, []);

  return (
    <MainWrapper>
      <Navigation />
      <MainWrapperFavorite
        className={
          localStorage.getItem("navigationOpen") === "true"
            ? ""
            : "fullWidthPage"
        }
      >
        {loadingInfo ? (
          <LoadingWrapper>
            <Oval
              visible={true}
              height="80"
              width="80"
              secondaryColor={
                localStorage.getItem("theme") === "light"
                  ? "#4E5255"
                  : "#CFDAE6"
              }
              color={
                localStorage.getItem("theme") === "light"
                  ? "#2363F7"
                  : "#4fa94d"
              }
              ariaLabel="oval-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </LoadingWrapper>
        ) : (
          <>
            <TopWrapper>
              <IntroText
                smallTitle="Hello John"
                title="Check Out All Competitions."
              />
            </TopWrapper>

            <Breadcrumbs items={breadcrumbs} />

            <TopCard>
              <TopContent>
                <LeftInfo>
                  <img
                    src={info?.competition?.logo ?? placeholder}
                    alt={info?.competition?.logo ?? placeholder}
                  />
                </LeftInfo>
                <RightInfo>
                  <h3>{info?.title}</h3>
                  <h5>{info?.teams?.length} Clubs</h5>
                </RightInfo>
              </TopContent>
            </TopCard>

            {/* Tabs */}
            <TabsWrapper>
              <TopList>
                <TabListWrapper>
                  <TabTitle>matches</TabTitle>
                  <TabTitle>Player Rankings</TabTitle>
                  <TabTitle>Team Rankings</TabTitle>
                  {/* <TabTitle>teams table</TabTitle> */}
                </TabListWrapper>
              </TopList>

              <TabContent>
                <CompetitionMatch
                  table={info?.matches}
                  load={loading}
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                  teamParams={teamParams}
                  availableSeasons={info?.available_seasons}
                  initialSeasonTitle={info?.title}
                  id={id}
                />
              </TabContent>
              <TabContent>
                <BestPlayers table={bestPlayersInfo} info={info} />
              </TabContent>
              <TabContent>
                <BestTeams table={bestTeamsInfo} info={info} />
              </TabContent>
              <TabContent>
                <TeamsTable table={teamsTable} />
              </TabContent>
            </TabsWrapper>
          </>
        )}
      </MainWrapperFavorite>
    </MainWrapper>
  );
};

export default Index;
