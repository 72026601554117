import React, { useState } from "react";

// Style
import {
  TableWrap,
  TableContent,
  ProfileImage,
  LeftContent,
  ImagesWrap,
  RightContent,
  SeeMoreWrap,
} from "../../style/FavoriteTable";

// Images
import star from "../../images/ic_start.svg";
import emptyState from "../../images/contactLogo.svg";

import { getTeamParamMarkers } from "../../helpers";

// Language
import { useTranslation } from "react-i18next";
import {
  BestPlayerClub,
  BestPlayerContent,
  BestPlayerName,
  BestPlayersTable,
  BestPlayerValue,
  BestPlayerWrap,
  BestPlayerWrapper,
} from "../../style/TournamentsPage";

import { NotificationList } from "../notification/NotificationList";
import { useNotification } from "../notification/useNotification";

const BestTeams = ({ table, info }) => {
  const { t, i18n } = useTranslation();

  const [expandedIndices, setExpandedIndices] = useState([]);

  const toggleShowLeads = (index) => {
    setExpandedIndices((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  const { notifications, addNotification, removeNotification } =
    useNotification();

  const handleClickPlayerParam = async (param, team) => {
    const { parameter_id: param_id, parameter_name } = param;
    const { team: { id: team_id, name }, value: count } = team;
    try {
      const controller = new AbortController();
      addNotification(`Fetching clips`, "info", controller);
      document.body.classList.add("loading-cursor");
      const data = await getTeamParamMarkers(
        team_id,
        param_id,
        "in_attack",
        info?.year_season?.id ?? '',
        controller
      );
      if (data?.results?.length > 0) {
        const markers = data.results.map((item) => item.id).join(",");
        window.open(`/tournaments/match/video?title=${parameter_name} - ${name}&markers=${markers}`);
      } else {
        addNotification(
          `There are 0 clips in the response from the server.`,
          "warning"
        );
      }
    } catch (e) {
      console.log("error get param markers", e);
      if (e.type === "canceled") {
        addNotification(
          `The request to receive ${count} clips of the team has been canceled.`,
          "warning"
        );
        return;
      }
      addNotification(`Error in receiving ${count} team clips.`, "error");
    } finally {
      document.body.classList.remove("loading-cursor");
    }
  };

  const isCanClickPlayerStat = (teams, item) => {
    return (
      item.leaders?.count === 0 ||
      item.parameter_name.includes("%") ||
      item.parameter_name.includes("Mins Played")
    );
  };

  return (
    <>
      <BestPlayerWrap>
        {table.map((item, index) => {
          return (
            <BestPlayersTable key={index} className="teamCardSize">
              <h2>{item.parameter_name}</h2>
              {item.leaders.length > 0 ? (
                <>
                  {item.leaders.slice(0, 5).map((teams, index) => {
                    return (
                      <BestPlayerContent
                        key={index}
                        className="bestTeamContent"
                      >
                        <BestPlayerWrapper>
                          <BestPlayerName
                            href={`/tournaments/team/${teams.team.id}`}
                          >
                            {/* <img src={star} alt={star} /> */}
                            <img
                              src={teams.team.logo ?? emptyState}
                              alt={teams.team.logo ?? emptyState}
                              className="profileImage"
                            />
                            <h5>{teams.team.name}</h5>
                          </BestPlayerName>
                          <BestPlayerValue>
                            <h5
                              style={
                                isCanClickPlayerStat(teams, item)
                                  ? { cursor: "not-allowed" }
                                  : { cursor: "pointer" }
                              }
                              onClick={() =>
                                !isCanClickPlayerStat(teams, item) &&
                                handleClickPlayerParam(item, teams)
                              }
                            >
                              {teams.value}
                            </h5>
                          </BestPlayerValue>
                        </BestPlayerWrapper>
                      </BestPlayerContent>
                    );
                  })}
                  {item?.leaders?.length > 5 && (
                    <>
                      {expandedIndices.includes(index)
                        ? item?.leaders?.slice(5)?.map((teams, index) => (
                          <BestPlayerContent
                            key={index}
                            className="bestTeamContent"
                          >
                            <BestPlayerWrapper>
                              <BestPlayerName
                                href={`/tournaments/team/${teams.team.id}`}
                              >
                                {/* <img src={star} alt={star} /> */}
                                <img
                                  src={teams.team.logo ?? emptyState}
                                  alt={teams.team.logo ?? emptyState}
                                  className="profileImage"
                                />
                                <h5>{teams.team.name}</h5>
                              </BestPlayerName>
                              <BestPlayerValue>
                                <h5
                                  style={
                                    isCanClickPlayerStat(teams, item)
                                      ? { cursor: "not-allowed" }
                                      : { cursor: "pointer" }
                                  }
                                  onClick={() =>
                                    !isCanClickPlayerStat(teams, item) &&
                                    handleClickPlayerParam(item, teams)
                                  }
                                >
                                  {teams.value}
                                </h5>
                              </BestPlayerValue>
                            </BestPlayerWrapper>
                          </BestPlayerContent>
                        ))
                        : null}
                      <button onClick={() => toggleShowLeads(index)}>
                        {expandedIndices.includes(index)
                          ? "Show less"
                          : "Show more"}
                      </button>
                    </>
                  )}
                </>
              ) : (
                <h2>No data</h2>
              )}
            </BestPlayersTable>
          );
        })}
      </BestPlayerWrap>

      {/* display notifications */}
      <NotificationList
        notifications={notifications}
        onClose={removeNotification}
      />
    </>
  );
};

export default BestTeams;
