import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    VideoActions,
    VideoActionsContent,
    VideoActionsContentTop,
    VideoLists
} from '../../style/VideoStyle';

import { ReactComponent as PlayVideo } from "../../images/ic_play-button.svg";
import { InputWrapper } from '../inputs/TextInput';
import Modal from "../../components/modal/index";

import { ReactComponent as Edit } from "../../images/ic_edit.svg";
import { ReactComponent as Trash } from "../../images/ic_bin.svg";
import Button from '../button';
import { $apiV1 } from '../../config/api';
import { useNavigate } from 'react-router-dom';

const VideoListItem = ({
    item,
    currentVideoIndex,
    index,
    handlePlayVideo,
    parentWidth,
    handleDeleteVideo,
    playlist,
    isAuth,
}) => {
    const { t } = useTranslation();
    const [openRenameVideo, setOpenRenameVideo] = useState(false);
    const [openDeleteVideo, setOpenDeleteVideo] = useState(false);
    const [namveVideo, setNameVideo] = useState(item?.title);

    const handleDelete = async (deleteFolderInfo) => {
        try {
            await $apiV1.delete(`/users/playlists/${playlist.id}/${deleteFolderInfo}/`);
            setOpenDeleteVideo(!openDeleteVideo);
            handleDeleteVideo(deleteFolderInfo)
        } catch (error) {
            console.error("Error fetching match data:", error);
        }
    };

    const handleRenameVideoName = async (id) => {
        try {
            await $apiV1.put(`/users/playlists/${playlist.id}/${id}/`, {
                title: namveVideo
            });
        } catch (error) {
            console.error("Error fetching match data:", error);
        }
    }

    const handleClickRename = (item) => {
        setOpenRenameVideo(!openRenameVideo)
        if (openRenameVideo) {
            handleRenameVideoName(item.id)
        }
    }

    return (
        <VideoLists
            className={currentVideoIndex === index ? 'active' : ''}
            title={item?.title}
        >
            <>
                <div className="videoList">
                    <PlayVideo
                        onClick={() => handlePlayVideo(item)}

                    />
                    {
                        openRenameVideo
                            ? <InputWrapper>
                                <input
                                    value={namveVideo}
                                    onChange={e => setNameVideo(e.target.value)}
                                    onKeyDown={(e) => e.key === 'Enter' && (handleClickRename(item))}
                                />
                            </InputWrapper>
                            : <h3
                                style={{ maxWidth: parentWidth - 66 || 255 }}
                            >
                                {" "}
                                {namveVideo}
                            </h3>
                    }


                </div>
                {isAuth && (
                    <div style={{ display: 'flex', alignItems: 'center', gap: 15 }}>
                        <div
                            onClick={() => handleClickRename(item)}
                        >
                            <Edit />
                        </div>
                        <div
                            onClick={() =>
                                setOpenDeleteVideo(!openDeleteVideo)
                            }
                        >
                            <Trash />
                        </div>
                    </div>
                )}

            </>

            {/* Delete folder */}
            <Modal isOpen={openDeleteVideo} setIsOpen={setOpenDeleteVideo} closeIc>
                <h1>{t("playlist.deleteFolderTitle")}</h1>
                <p style={{ marginBottom: "20px" }}>{t("playlist.deleteFolderDesc")}</p>
                <Button
                    title={t("playlist.deleteFolderButton")}
                    size={"full"}
                    onClick={() => handleDelete(item.id)}
                />
            </Modal>
        </VideoLists>
    )
}

export const VideoListPanel = (props) => {

    const navigate = useNavigate()

    const {
        handlePlayVideo,
        currentVideoIndex,
        playlist,
    } = props;
    const parent = useRef(null);

    const [videos, setVideos] = useState([]);

    const isAuth = localStorage.getItem('token');

    useEffect(() => {
        if (playlist?.videos) {
            setVideos(playlist.videos);
        }
    }, [playlist])

    const handleDeleteVideo = (video) => {
        const newVideos = [...videos].filter(item => item.id !== video);
        setVideos(newVideos);
    }

    return (
        <VideoActions ref={parent}>

            <VideoActionsContent>
                <h3 className='video-actions-title'>{playlist?.title ?? 'Playlist'}</h3>
                <VideoActionsContentTop>
                    {videos?.map((item, index) => {
                        return (
                            <VideoListItem
                                key={item.id}
                                index={index}
                                currentVideoIndex={currentVideoIndex}
                                item={item}
                                handlePlayVideo={handlePlayVideo}
                                parentWidth={parent.current?.offsetWidth}
                                handleDeleteVideo={handleDeleteVideo}
                                playlist={playlist}
                                isAuth={isAuth}
                            />
                        );
                    })}
                </VideoActionsContentTop>
                <br />
                <Button title="go back" onClick={() =>
                    navigate(
                        `/my-playlists`
                    )
                } style={{ margin: "0 20px" }} />
            </VideoActionsContent>

        </VideoActions>
    )
}
