import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ScrollableContainer, StyledTable } from "../../style/MatchTable";
import {
  GlobalWrap,
  PlayerTable,
  LeftInfo,
  RightInfo,
  TopHeader,
  StatInfo,
  PlayerPrimaryInfo,
  PlayerStatistics,
  PlayerPrimaryInfoContent,
  Club,
  TableInfo,
} from "../../style/PlayersStat";
import placeholder from "../../images/ic_logo.svg";
import { Tooltip as ReactTooltip } from "react-tooltip";
import starOn from "../../images/star_on.svg";
import starOff from "../../images/star_off.svg";
import { $apiV1 } from "../../config/api";

const GoalKeeperStat = ({ items, club, tempSelectedFilters, gkPlayerSelectedFilters }) => {
  const [favoritePlayers, setFavoritePlayers] = useState({});

  const filteredHomeTeam = items?.home_team?.filter((player) =>
    player.positions.includes("GK")
  );

  const filteredAwayTeam = items?.away_team?.filter((player) =>
    player.positions.includes("GK")
  );

  const navigate = useNavigate();

  const toggleFavorite = async (item) => {
    try {
      const isCurrentlyFavorite = favoritePlayers[item.id] ?? false;

      if (isCurrentlyFavorite) {
        await $apiV1.delete(`/users/favorites/players/${item.id}/`);
      } else {
        await $apiV1.post(`/users/favorites/players/`, { player: item.id });
      }

      setFavoritePlayers((prev) => ({
        ...prev,
        [item.id]: !isCurrentlyFavorite,
      }));
    } catch (error) {
      console.error("Error toggling favorite status:", error);
    }
  };

  return (
    <GlobalWrap>
      <PlayerTable>
        <Club>
          <h2>{club?.home_team}</h2>
        </Club>
        <TableInfo>
          <LeftInfo>
            <TopHeader>
              <h3>#</h3>
              <h3>Player</h3>
              <h3 className="position">Pos.</h3>
            </TopHeader>
            <StatInfo>
              <PlayerPrimaryInfo>
                {items?.home_team?.map((item, index) => {
                  const isFavorite = favoritePlayers[item.id] ?? false;
                  if (item.positions.includes("GK")) {
                    return (
                      <PlayerPrimaryInfoContent key={index}>
                        <img
                          src={isFavorite ? starOn : starOff}
                          alt="Favorite star"
                          style={{
                            width: "18px",
                            height: "100%",
                            cursor: "pointer",
                          }}
                          onClick={() => toggleFavorite(item)}
                        />
                        <img
                          src={
                            item.photo
                              ? `https://platform.smrtstats.com:8888/${item.photo}`
                              : placeholder
                          }
                        />

                        <h3>{item.number}</h3>
                        <h3
                          data-tooltip-id={`full-name-${item.name}-${item.surname}`}
                        >
                          {item.name} {item.surname}
                        </h3>

                        <ReactTooltip
                          id={`full-name-${item.name}-${item.surname}`}
                          place="bottom"
                          content={`${item.name} ${item.surname}`}
                        />

                        <h3 className="position">{item.positions[0]}</h3>
                      </PlayerPrimaryInfoContent>
                    );
                  }
                  return null;
                })}
              </PlayerPrimaryInfo>
            </StatInfo>
          </LeftInfo>
          <RightInfo>
            <ScrollableContainer>
              <StyledTable>
                <thead>
                  <tr>
                    {gkPlayerSelectedFilters?.checkedIds?.length === 0
                      ? null
                      : filteredHomeTeam
                          ?.find((item) => item.stats?.length > 0)
                          ?.stats?.filter(
                            (stat) =>
                              gkPlayerSelectedFilters?.checkedIds?.length ===
                                0 ||
                              gkPlayerSelectedFilters?.checkedIds?.includes(
                                stat.parameter_id
                              )
                          )
                          ?.map((stat) => (
                            <th
                              key={stat.parameter_id}
                              style={{ paddingBottom: 0 }}
                              title={stat.parameter_name}
                            >
                              {stat.parameter_name}
                            </th>
                          ))}
                  </tr>
                </thead>

                <tbody className="playerStatsTable">
                  {gkPlayerSelectedFilters?.checkedIds?.length === 0
                    ? null
                    : filteredHomeTeam?.map((player, index) => (
                        <tr key={index}>
                          {player?.stats
                            ?.filter(
                              (stat) =>
                                gkPlayerSelectedFilters?.checkedIds?.length ===
                                  0 ||
                                gkPlayerSelectedFilters?.checkedIds?.includes(
                                  stat.parameter_id
                                )
                            )
                            ?.sort((a, b) => a?.parameter_id - b?.parameter_id)
                            .map((stat) => (
                              <td
                                key={stat.parameter_id}
                                className="tableNewStyle"
                                style={{
                                  // color: "#fff",
                                  cursor: stat.markers ? "pointer" : "",
                                }}
                                onClick={() =>
                                  navigate(
                                    `/tournaments/match/video?title=${
                                      stat.parameter_name
                                    } - ${player?.name} ${
                                      player?.surname
                                    }&markers=${stat.markers?.join(",")}`
                                  )
                                }
                              >
                                {Number.isInteger(stat.value)
                                  ? stat.value.toLocaleString()
                                  : typeof stat.value === "number"
                                  ? stat.value.toFixed(1)
                                  : stat.value}
                              </td>
                            ))}
                        </tr>
                      ))}
                </tbody>
              </StyledTable>
            </ScrollableContainer>
          </RightInfo>
        </TableInfo>
      </PlayerTable>

      <PlayerTable>
        <Club style={{ marginTop: "20px" }}>
          <h2>{club?.away_team}</h2>
        </Club>
        <TableInfo>
          <LeftInfo>
            <TopHeader>
              <h3>#</h3>
              <h3>Player</h3>
              <h3 className="position">Pos.</h3>
            </TopHeader>
            <StatInfo>
              <PlayerPrimaryInfo>
                {items?.away_team?.map((item, index) => {
                  const isFavorite = favoritePlayers[item.id] ?? false;

                  if (item.positions.includes("GK")) {
                    return (
                      <PlayerPrimaryInfoContent key={index}>
                        <img
                          src={isFavorite ? starOn : starOff}
                          alt="Favorite star"
                          style={{
                            width: "18px",
                            height: "100%",
                            cursor: "pointer",
                          }}
                          onClick={() => toggleFavorite(item)}
                        />
                        <img
                          src={
                            item.photo
                              ? `https://platform.smrtstats.com:8888/${item.photo}`
                              : placeholder
                          }
                        />
                        <h3>{item.number}</h3>
                        <h3
                          data-tooltip-id={`full-name-${item.name}-${item.surname}`}
                        >
                          {item.name} {item.surname}
                        </h3>

                        <ReactTooltip
                          id={`full-name-${item.name}-${item.surname}`}
                          place="bottom"
                          content={`${item.name} ${item.surname}`}
                        />

                        <h3 className="position">{item.positions[0]}</h3>
                      </PlayerPrimaryInfoContent>
                    );
                  }
                  return null;
                })}
              </PlayerPrimaryInfo>
            </StatInfo>
          </LeftInfo>
          <RightInfo>
            <ScrollableContainer>
              <StyledTable>
                <thead>
                  <tr>
                    {gkPlayerSelectedFilters?.checkedIds?.length === 0
                      ? null
                      : filteredAwayTeam
                          ?.find((item) => item.stats?.length > 0)
                          ?.stats?.filter(
                            (stat) =>
                              gkPlayerSelectedFilters?.checkedIds?.length ===
                                0 ||
                              gkPlayerSelectedFilters?.checkedIds?.includes(
                                stat.parameter_id
                              )
                          )
                          ?.map((stat) => (
                            <th
                              key={stat.parameter_id}
                              style={{ paddingBottom: 0 }}
                              title={stat.parameter_name}
                            >
                              {stat.parameter_name}
                            </th>
                          ))}
                  </tr>
                </thead>

                <tbody className="playerStatsTable">
                  {gkPlayerSelectedFilters?.checkedIds?.length === 0
                    ? null
                    : filteredAwayTeam?.map((player, index) => (
                        <tr key={index}>
                          {player?.stats
                            ?.filter(
                              (stat) =>
                                gkPlayerSelectedFilters?.checkedIds?.length ===
                                  0 ||
                                gkPlayerSelectedFilters?.checkedIds?.includes(
                                  stat.parameter_id
                                )
                            )
                            ?.sort((a, b) => a?.parameter_id - b?.parameter_id)
                            .map((stat) => (
                              <td
                                key={stat.parameter_id}
                                className="tableNewStyle"
                                style={{
                                  // color: "#fff",
                                  cursor: stat.markers ? "pointer" : "",
                                }}
                                onClick={() =>
                                  navigate(
                                    `/tournaments/match/video?title=${
                                      stat.parameter_name
                                    } - ${player?.name} ${
                                      player?.surname
                                    }&markers=${stat.markers?.join(",")}`
                                  )
                                }
                              >
                                {Number.isInteger(stat.value)
                                  ? stat.value.toLocaleString()
                                  : typeof stat.value === "number"
                                  ? stat.value.toFixed(1)
                                  : stat.value}
                              </td>
                            ))}
                        </tr>
                      ))}
                </tbody>
              </StyledTable>
            </ScrollableContainer>
          </RightInfo>
        </TableInfo>
      </PlayerTable>
    </GlobalWrap>
  );
};

export default GoalKeeperStat;
