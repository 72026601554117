import React, { useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";

// Media Query
import { useMediaQuery } from "react-responsive";
import { breakpoints } from "../../style/size";

// Language
import { useTranslation } from "react-i18next";
import {
  BottomCardsWrap,
  Cards,
  CardsWrap,
  InnerContent,
  LoadingState,
  OverviewCard,
  OverviewWrap,
  StatsCard,
  TopCardInfo,
  TopCardsWrap,
  VideoContent,
  VideoInfo,
  VideoItems,
  VideoList,
  VideoStats,
  WrappBottomContent,
  WrappContent,
  LoadingVideo,
} from "../../style/Overview";

// Images
import play from "../../images/ic_play-button.svg";
import playBlue from "../../images/play_blue.svg";
import { useNavigate } from "react-router-dom";
import { EmptyCard, SelectDropdown } from "../../style/Global";
import { getPlayerParamMarkers, getTeamAttackVideos, getTeamDefenseVideos, getTeamParamMarkers, groupByNestedProperty } from "../../helpers";
import { NotificationList } from "../notification/NotificationList";
import { useNotification } from "../notification/useNotification";
import Matches from "./Matches";

const Overview = ({
  table,
  leaders,
  loadingList,
  id,
  handleSelectChange,
  selectedSeason,
  attackVideosSet1,
  attackVideosSet2,
  defencekVideosSet1,
  defenceVideosSet2,
  loadingAttackVideosSet1,
  loadingAttackVideosSet2,
  loadingDefencekVideosSet1,
  loadingDefenceVideosSet2,
  statMatch,
  teamInfo,
  loadingStatMatch
}) => {
  const [expandedIndices, setExpandedIndices] = useState([]);

  const navigate = useNavigate();

  const { notifications, addNotification, removeNotification } = useNotification();

  const toggleShowLeads = (index) => {
    setExpandedIndices((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };


  const handleClickPlayerParam = async (param, player) => {
    const { parameter_id: param_id, parameter_name } = param;
    const { player: { id: player_id, display_name }, count } = player;
    try {
      const controller = new AbortController();
      addNotification(`Fetching clips`, "info", controller);
      document.body.classList.add('loading-cursor');
      const data = await getPlayerParamMarkers(player_id, param_id, selectedSeason, controller);
      if (data?.results?.length > 0) {
        const markers = data.results.map(item => item.id).join(',');
        window.open(`/tournaments/match/video?title=${parameter_name} - ${display_name}&markers=${markers}`)
      } else {
        addNotification(`There are 0 clips in the response from the server.`, 'warning')
      }
    } catch (e) {
      console.log('error get param markers', e);
      if (e.type === 'canceled') {
        addNotification(`The request to receive ${count} clips of the player has been canceled.`, 'warning')
        return
      }
      addNotification(`Error in receiving ${count} player clips.`, 'error')
    } finally {
      document.body.classList.remove('loading-cursor');
    }
  }

  const handleClickTeamParam = async (param) => {
    const { id: param_id, name, count } = param;
    try {
      const controller = new AbortController();
      addNotification(`Fetching clips`, "info", controller);
      document.body.classList.add('loading-cursor');
      const data = await getTeamParamMarkers(id, param_id, 'in_attack', selectedSeason, controller);
      if (data?.results?.length > 0) {
        const markers = data.results.map(item => item.id).join(',');
        window.open(`/tournaments/match/video?title=${name} - ${table?.team?.name}&markers=${markers}`)
      } else {
        addNotification(`There are 0 clips in the response from the server.`, 'warning')
      }
    } catch (e) {
      console.log('error get param markers', e);
      if (e.type === 'canceled') {
        addNotification(`The request to receive ${count} clips of the team has been canceled.`, 'warning')
        return
      }
      addNotification(`Error in receiving team events. ${e.message}`, 'error')
    } finally {
      document.body.classList.remove('loading-cursor');
    }
  }

  const isCanClickPlayerStat = (leader, item) => {
    return leader?.count === 0
      || item.parameter_name.includes('%')
      || item.parameter_name.includes('Mins Played')
  }

  const isCanClickTeamStat = (item) => {
    return item.count === 0
      || item.name.includes('%')
      || item.name.includes('Mins Played')
  }

  const formatNumber = (number) => {
    return new Intl.NumberFormat("en-US").format(number);
  };

  return (
    <OverviewWrap>
      <TopCardsWrap>
        <WrappContent>
          <TopCardInfo>
            <h2>Stats</h2>
            {/* <SelectDropdown>
              <select onChange={handleSelectChange} value={selectedSeason}>
                {table.available_seasons?.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      Season {item.years}
                    </option>
                  );
                })}
              </select>
            </SelectDropdown> */}
          </TopCardInfo>
          <OverviewCard>
            {loadingList ? (
              <LoadingState style={{ margin: "20px" }}>
                <Oval
                  visible={true}
                  height="80"
                  width="80"
                  secondaryColor={
                    localStorage.getItem("theme") === "light"
                      ? "#4E5255"
                      : "#CFDAE6"
                  }
                  color={
                    localStorage.getItem("theme") === "light"
                      ? "#2363F7"
                      : "#4fa94d"
                  }
                  ariaLabel="oval-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </LoadingState>
            ) : (
              <InnerContent>
                {leaders && Object.keys(leaders.main_stats).length > 0 ? (
                  <>
                    {leaders.main_stats.map((item, index) => {
                      return (
                        <div key={index} className="statistics statsPadding">
                          <h5>{item.name}:</h5>
                          <h4
                            style={
                              isCanClickTeamStat(item)
                                ? { cursor: "not-allowed" }
                                : { cursor: "pointer" }
                            }
                            onClick={() =>
                              !isCanClickTeamStat(item) &&
                              handleClickTeamParam(item)
                            }
                          >
                            {item.count ? formatNumber(item.count) : item.count}
                          </h4>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <EmptyCard>
                    <h2>No data</h2>
                  </EmptyCard>
                )}
              </InnerContent>
            )}
          </OverviewCard>
        </WrappContent>
        {loadingStatMatch ? (
          <LoadingState>
            <Oval
              visible={true}
              height="80"
              width="80"
              secondaryColor={
                localStorage.getItem("theme") === "light"
                  ? "#4E5255"
                  : "#CFDAE6"
              }
              color={
                localStorage.getItem("theme") === "light"
                  ? "#2363F7"
                  : "#4fa94d"
              }
              ariaLabel="oval-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </LoadingState>
        ) : (
          <Matches
            table={statMatch || []}
            // onChangeData={(data) => setPlayers(data)}
            styleTableWrapper={{
              height: '300px',
              overflowY: 'scroll',
              scrollbarWidth: 'auto',
            }}
            showTitle={true}
            season={selectedSeason}
            playerTeams={teamInfo?.team ? [teamInfo.team] : []}
          />
        )}
      </TopCardsWrap>

      <>
        {
          loadingList ? (
            <LoadingState>
              <Oval
                visible={true}
                height="80"
                width="80"
                secondaryColor={
                  localStorage.getItem("theme") === "light"
                    ? "#4E5255"
                    : "#CFDAE6"
                }
                color={
                  localStorage.getItem("theme") === "light"
                    ? "#2363F7"
                    : "#4fa94d"
                }
                ariaLabel="oval-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </LoadingState>
          ) : (
            <WrappContent style={{ marginBottom: 30 }}>
              <TopCardInfo>
                <h2>Team Leaders</h2>
                <SelectDropdown>
                  <select onChange={handleSelectChange} value={selectedSeason}>
                    {table.available_seasons?.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          Season {item.years}
                        </option>
                      );
                    })}
                  </select>
                </SelectDropdown>
              </TopCardInfo>
              <OverviewCard>
                <InnerContent>
                  <CardsWrap>
                    {leaders?.team_leaders.length > 0 ? (
                      leaders?.team_leaders.map((item, index) => (
                        <Cards key={index}>
                          <h3 title={item?.parameter_name}>{item?.parameter_name}</h3>
                          {item?.leaders.slice(0, 5).map((leader, idx) => (
                            <div key={idx} className="statistics">
                              <h5
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  navigate(
                                    `/tournaments/player/${leader?.player?.id}`
                                  )
                                }
                              >
                                {leader?.player?.display_name}:
                              </h5>
                              <h4
                                style={
                                  isCanClickPlayerStat(leader, item)
                                    ? { cursor: "not-allowed" }
                                    : { cursor: "pointer" }
                                }
                                onClick={() =>
                                  !isCanClickPlayerStat(leader, item) &&
                                  handleClickPlayerParam(item, leader)
                                }
                              >
                                {leader?.count}
                              </h4>
                            </div>
                          ))}
                          {item?.leaders?.length >= 5 && (
                            <button onClick={() => toggleShowLeads(index)}>
                              {expandedIndices.includes(index)
                                ? "Show less"
                                : "Show more"}
                            </button>
                          )}
                          {expandedIndices.includes(index) && (
                            <>
                              {item?.leaders.slice(5).map((leader, idx) => (
                                <div key={idx} className="statistics">
                                  <h5
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      navigate(
                                        `/tournaments/player/${leader?.player?.id}`
                                      )
                                    }
                                  >
                                    {leader?.player?.display_name}:
                                  </h5>
                                  <h4
                                    style={
                                      isCanClickPlayerStat(leader, item)
                                        ? { cursor: "not-allowed" }
                                        : { cursor: "pointer" }
                                    }
                                    onClick={() =>
                                      !isCanClickPlayerStat(leader, item) &&
                                      handleClickPlayerParam(item, leader)
                                    }
                                  >
                                    {leader?.count}
                                  </h4>
                                </div>
                              ))}
                            </>
                          )}
                        </Cards>
                      ))
                    ) : (
                      <EmptyCard>
                        <h2>No data</h2>
                      </EmptyCard>
                    )}
                  </CardsWrap>
                </InnerContent>
              </OverviewCard>
            </WrappContent>
          )
        }
      </>


      <BottomCardsWrap>
        <WrappBottomContent>
          <TopCardInfo>
            <h2>{table?.team?.name} in Attack: Videos</h2>
          </TopCardInfo>
          <OverviewCard
            style={{ height: "fit-content", scrollbarWidth: "none" }}
          >
            <InnerContent>
              <VideoContent>
                <VideoStats>
                  <StatsCard>
                    <h4
                      style={{
                        color:
                          localStorage.getItem("theme") === "light"
                            ? "#015ead"
                            : "#fff",
                      }}
                    >
                      {attackVideosSet1?.count}
                    </h4>
                    <h5
                      style={{
                        color:
                          localStorage.getItem("theme") === "light"
                            ? "#015ead"
                            : "#36e82d",
                      }}
                    >
                      Goals scored
                    </h5>
                  </StatsCard>

                  {loadingAttackVideosSet1 ? (
                    <LoadingVideo>
                      <Oval
                        visible={true}
                        height="80"
                        width="80"
                        secondaryColor={
                          localStorage.getItem("theme") === "light"
                            ? "#4E5255"
                            : "#CFDAE6"
                        }
                        color={
                          localStorage.getItem("theme") === "light"
                            ? "#2363F7"
                            : "#4fa94d"
                        }
                        ariaLabel="oval-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                      />
                    </LoadingVideo>
                  ) : (
                    <VideoList>
                      {attackVideosSet1?.results?.length > 0 ? (
                        <>
                          {Object.entries(
                            groupByNestedProperty(
                              attackVideosSet1.results,
                              "action.name"
                            )
                          ).map(([key, value]) => {
                            const ids = value?.map((item) => item.id);
                            return (
                              <VideoItems
                                key={key}
                                onClick={() =>
                                  navigate(
                                    `/tournaments/match/video?title=${leaders?.in_attack[0]?.param_name
                                    } - ${table?.team?.name
                                    }&markers=${ids?.join(",")}`
                                  )
                                }
                              >
                                <img
                                  src={
                                    localStorage.getItem("theme") === "light"
                                      ? playBlue
                                      : play
                                  }
                                  alt={play}
                                  style={{ cursor: "pointer" }}
                                />

                                <VideoInfo>
                                  <h3>{key}</h3>{" "}
                                  <span>{value.length} videos</span>
                                </VideoInfo>
                              </VideoItems>
                            );
                          })}
                        </>
                      ) : (
                        <VideoInfo>
                          <h3>No data</h3>{" "}
                        </VideoInfo>
                      )}
                    </VideoList>
                  )}
                </VideoStats>

                <VideoStats>
                  <StatsCard>
                    <h4
                      style={{
                        color:
                          localStorage.getItem("theme") === "light"
                            ? "#015ead"
                            : "#fff",
                      }}
                    >
                      {attackVideosSet2?.count}
                    </h4>
                    <h5
                      style={{
                        color:
                          localStorage.getItem("theme") === "light"
                            ? "#015ead"
                            : "#36e82d",
                      }}
                    >
                      Goal Opportunities
                    </h5>
                  </StatsCard>

                  {loadingAttackVideosSet2 ? (
                    <LoadingVideo>
                      <Oval
                        visible={true}
                        height="80"
                        width="80"
                        secondaryColor={
                          localStorage.getItem("theme") === "light"
                            ? "#4E5255"
                            : "#CFDAE6"
                        }
                        color={
                          localStorage.getItem("theme") === "light"
                            ? "#2363F7"
                            : "#4fa94d"
                        }
                        ariaLabel="oval-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                      />
                    </LoadingVideo>
                  ) : (
                    <VideoList>
                      {attackVideosSet2?.results?.length > 0 ? (
                        <>
                          {Object.entries(
                            groupByNestedProperty(
                              attackVideosSet2.results,
                              "action.name"
                            )
                          ).map(([key, value]) => {
                            const ids = value?.map((item) => item.id);
                            return (
                              <VideoItems
                                key={key}
                                onClick={() =>
                                  navigate(
                                    `/tournaments/match/video?title=${leaders?.in_attack[1]?.param_name
                                    } - ${table?.team?.name
                                    }&markers=${ids?.join(",")}`
                                  )
                                }
                              >
                                <img
                                  src={
                                    localStorage.getItem("theme") === "light"
                                      ? playBlue
                                      : play
                                  }
                                  alt={play}
                                  style={{ cursor: "pointer" }}
                                />

                                <VideoInfo>
                                  <h3>{key?.replace(/Chances?/, 'Goals')}</h3>{" "}
                                  <span>{value.length} videos</span>
                                </VideoInfo>
                              </VideoItems>
                            );
                          })}
                        </>
                      ) : (
                        <VideoInfo>
                          <h3>No data</h3>{" "}
                        </VideoInfo>
                      )}
                    </VideoList>
                  )}
                </VideoStats>
              </VideoContent>
            </InnerContent>
          </OverviewCard>
        </WrappBottomContent>

        <WrappBottomContent>
          <TopCardInfo>
            <h2>{table?.team?.name} in Defense: Videos</h2>
          </TopCardInfo>
          <OverviewCard
            style={{ height: "fit-content", scrollbarWidth: "none" }}
          >
            <InnerContent>
              <VideoContent>
                <VideoStats>
                  <StatsCard className="defense">
                    <h4
                      style={{
                        color:
                          localStorage.getItem("theme") === "light"
                            ? "#015ead"
                            : "#fff",
                      }}
                    >
                      {defencekVideosSet1?.count}
                    </h4>
                    <h5
                      style={{
                        color:
                          localStorage.getItem("theme") === "light"
                            ? "#015ead"
                            : "#F20A46",
                      }}
                    >
                      Goals Conceeded
                    </h5>
                  </StatsCard>

                  {loadingDefencekVideosSet1 ? (
                    <LoadingVideo>
                      <Oval
                        visible={true}
                        height="80"
                        width="80"
                        secondaryColor={
                          localStorage.getItem("theme") === "light"
                            ? "#4E5255"
                            : "#CFDAE6"
                        }
                        color={
                          localStorage.getItem("theme") === "light"
                            ? "#2363F7"
                            : "#4fa94d"
                        }
                        ariaLabel="oval-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                      />
                    </LoadingVideo>
                  ) : (
                    <VideoList>
                      {defencekVideosSet1?.results?.length > 0 ? (
                        <>
                          {Object.entries(
                            groupByNestedProperty(
                              defencekVideosSet1.results,
                              "action.name"
                            )
                          ).map(([key, value]) => {
                            const ids = value?.map((item) => item.id);
                            return (
                              <VideoItems
                                key={key}
                                onClick={() =>
                                  navigate(
                                    `/tournaments/match/video?title=Goals Conceeded - ${table?.team?.name
                                    }&markers=${ids?.join(",")}`
                                  )
                                }
                              >
                                <img
                                  src={
                                    localStorage.getItem("theme") === "light"
                                      ? playBlue
                                      : play
                                  }
                                  alt={play}
                                  style={{ cursor: "pointer" }}
                                />

                                <VideoInfo>
                                  <h3>{key}</h3>{" "}
                                  <span>{value.length} videos</span>
                                </VideoInfo>
                              </VideoItems>
                            );
                          })}
                        </>
                      ) : (
                        <VideoInfo>
                          <h3>No data</h3>{" "}
                        </VideoInfo>
                      )}
                    </VideoList>
                  )}
                </VideoStats>

                <VideoStats>
                  <StatsCard className="defense">
                    <h4
                      style={{
                        color:
                          localStorage.getItem("theme") === "light"
                            ? "#015ead"
                            : "#fff",
                      }}
                    >
                      {defenceVideosSet2?.count}
                    </h4>
                    <h5
                      style={{
                        color:
                          localStorage.getItem("theme") === "light"
                            ? "#015ead"
                            : "#F20A46",
                      }}
                    >
                      Opponent’s Goal Opportunities
                    </h5>
                  </StatsCard>

                  {loadingDefenceVideosSet2 ? (
                    <LoadingVideo>
                      <Oval
                        visible={true}
                        height="80"
                        width="80"
                        secondaryColor={
                          localStorage.getItem("theme") === "light"
                            ? "#4E5255"
                            : "#CFDAE6"
                        }
                        color={
                          localStorage.getItem("theme") === "light"
                            ? "#2363F7"
                            : "#4fa94d"
                        }
                        ariaLabel="oval-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                      />
                    </LoadingVideo>
                  ) : (
                    <VideoList>
                      {defenceVideosSet2?.results?.length > 0 ? (
                        <>
                          {Object.entries(
                            groupByNestedProperty(
                              defenceVideosSet2.results,
                              "action.name"
                            )
                          ).map(([key, value]) => {
                            const ids = value?.map((item) => item.id);
                            return (
                              <VideoItems
                                key={key}
                                onClick={() =>
                                  navigate(
                                    `/tournaments/match/video?title=Oponent’s Chances - ${table?.team?.name
                                    }markers=${ids?.join(",")}`
                                  )
                                }
                              >
                                <img
                                  src={
                                    localStorage.getItem("theme") === "light"
                                      ? playBlue
                                      : play
                                  }
                                  alt={play}
                                  style={{ cursor: "pointer" }}
                                />

                                <VideoInfo>
                                  <h3>{key?.replace(/Chances?/, 'Goals')}</h3>{" "}
                                  <span>{value.length} videos</span>
                                </VideoInfo>
                              </VideoItems>
                            );
                          })}
                        </>
                      ) : (
                        <VideoInfo>
                          <h3>No data</h3>{" "}
                        </VideoInfo>
                      )}
                    </VideoList>
                  )}
                </VideoStats>
              </VideoContent>
            </InnerContent>
          </OverviewCard>
        </WrappBottomContent>
      </BottomCardsWrap>

      {/* display notifications */}
      <NotificationList
        notifications={notifications}
        onClose={removeNotification}
      />
    </OverviewWrap>
  );
};

export default Overview;
