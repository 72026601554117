import React, { useState, useEffect, useMemo } from "react";
import { useMediaQuery } from "react-responsive";
import { breakpoints } from "../../style/size";
import { useNavigate } from "react-router-dom";

// Style
import {
  MainWrapper,
  TopWrapper,
  MainWrapperFavorite,
  FilterContentWrap,
} from "../../style/DasboardMain";
import "react-tabs/style/react-tabs.css";
import {
  CompanreButton,
  IntroTopRight,
  IntroTopSearch,
  SearchWrap,
} from "../../style/SmrtSearch";

// Components
import Navigation from "../../components/Navigation/Navigation";
import IntroText from "../../components/introText/index";
import Button from "../../components/button";

// Images
import emptySearch from "../../images/ic_empty.svg";
import searchIcon from "../../images/ic_search.svg";
import arrowIconUp from "../../images/up.svg";
import arrowIconDown from "../../images/down.svg";

// Language
import { useTranslation } from "react-i18next";

// Axios
import SmrtSearchSidebar from "../../components/table/SmrtSearchSidebar";
import { EmptyState } from "../../style/Tournaments";
import Filter from "../../components/modal/Filter";
import { InputWrapper } from "../../components/inputs/TextInput";
import {
  OpenedDropdown,
  ParamCheckboxStyle,
} from "../../components/table/MatchTable";
import styled from "styled-components";
import { $apiV1, $apiV2 } from "../../config/api";

const fetchData = async (url, params) => {
  const queryString = new URLSearchParams(params).toString();
  const response = await fetch(`${url}?${queryString}`);
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

export const AgeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;

  @media only screen and (${breakpoints.mobile}) {
    flex-direction: column;
  }

  > div {
    width: 100%;
  }
`;


const displayedParamsFielder = {
  "Mins Played": 1,
  "Passes +": 36,
  "Grave Mistakes": 162,
  "Goals +": 2,
  "Assists": 201,
  "Passes +, %": 37,
  "Duels +, %": 124,
  "Crosses": 50,
  "Aerial Duels +, %": 129,
  "Interceptions +": 153,
  "Ball Recoveries": 158,
  "Dribbles Made +, %": 114,
  "Tackles Made": 142,
  "Shots on Target, %": 26,
}

export const displayedParamsFielderIds = Object.values(displayedParamsFielder);

const displayedsParamsGK = {
  "Mins Played": 1,
  "Aerial Duels +, %": 129,
  "Effective Saves": 176,
  "Goals conceded": 173,
}

export const displayedsParamsGKIds = Object.values(displayedsParamsGK);

const Index = () => {
  const isMobile = useMediaQuery({ query: `(${breakpoints.mobile})` });
  const [openFilter, setOpenFilter] = useState(false);
  const [gender, setGender] = useState("");
  const [search, setSearch] = useState("");
  const [olderAge, setOlderAge] = useState("");
  const [underAge, setUnderAge] = useState("");
  const [heightFrom, setHeightFrom] = useState("");
  const [heightTo, setHeightTo] = useState("");
  const [minPlayedFrom, setMinPlayerdFrom] = useState("");
  const [minPlayedTo, setMinPlayedTo] = useState("");
  const [goalsFrom, setGoalsFrom] = useState("");
  const [goalsTo, setGoalsTo] = useState("");
  const [assistFrom, setAssistFrom] = useState("");
  const [assistTo, setAssistTo] = useState("");
  const [passAccuracyFrom, setPassAccuracyFrom] = useState("");
  const [passAccuracyTo, setPassAccuracyTo] = useState("");
  const [duelsWonFrom, setDuelsWonFrom] = useState("");
  const [duelsWonTo, setDuelsWonTo] = useState("");
  const [aerialDuelsWonFrom, setAerialDuelsWonFrom] = useState("");
  const [aerialDuelsWonTo, setAerialDuelsWonTo] = useState("");
  const [crossesFrom, setCrossesFrom] = useState("");
  const [crossesTo, setCrossesTo] = useState("");
  const [interceptionFrom, setInterceptionFrom] = useState("");
  const [interceptionTo, setInterceptionTo] = useState("");
  const [ballRecoveriesFrom, setBallRecoveriesFrom] = useState("");
  const [ballRecoveriesTo, setBallRecoveriesTo] = useState("");
  const [dribblingsFrom, setDribblingsFrom] = useState("");
  const [dribblingsTo, setDribblingsTo] = useState("");
  const [tacklesFrom, setTacklesFrom] = useState("");
  const [tacklesTo, setTacklesTo] = useState("");
  const [shotsFrom, setShotsFrom] = useState("");
  const [shotsTo, setShotsTo] = useState("");
  const [effectiveSavesFrom, setEffectiveSavesFrom] = useState("");
  const [effectiveSavesTo, setEffectiveSavesTo] = useState("");
  const [cleanSheetsFrom, setCleanSheetsFrom] = useState("");
  const [cleanSheetsTo, setCleanSheetsTo] = useState("");

  const [teamType, setTeamType] = useState("");
  const [leg, setLeg] = useState("");

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [text, setText] = useState("");
  const [positions, setPositions] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState([]);

  const [openPositions, setOpenPositions] = useState(false);
  const [openCountry, setOpenCountry] = useState(false);
  const [openCompetition, setOpenCompetition] = useState(false);

  const [countryName, setCountryName] = useState("");
  const [country, setCountry] = useState([]);
  const [selectedContry, setSelectedCountry] = useState([]);

  const [competitionName, setCompetitionName] = useState("");
  const [competition, setCompetition] = useState([]);
  const [selectedCompetition, setSelectedCompetition] = useState([]);

  const [playerSearchResults, setPlayerSearchResults] = useState([]);

  const [genderName, setGenderName] = useState("");
  const [selectedGender, setSelectedGender] = useState("");
  const [openGender, setOpenGender] = useState(false);

  const [legName, setLegName] = useState("");
  const [selectedLeg, setSelectedLeg] = useState("");
  const [openLeg, setOpenLeg] = useState(false);

  const [teamTypeName, setTeamTypeName] = useState("");
  const [selectSeason, setSelectSeason] = useState([]);

  const [seasonName, setSeasonName] = useState("");
  const [pageSeason, setPageSeason] = useState(2);
  const [selectedSeason, setSelectedSeason] = useState([])

  const [selectedTeamType, setSelectedTeamType] = useState("");
  const [openTeamType, setOpenTeamType] = useState(false);
  const [openSeason, setOpenSeason] = useState(false);

  const [page, setPage] = useState(1);
  const [totalPlayers, setTotalPlayers] = useState(0);
  const playersPerPage = 10; // Number of players per page

  const [loadingSearch, setLoadingSearch] = useState(true);

  const isGkPosSelected = useMemo(() => {
    return selectedPosition?.findIndex(item => item.id === 10) >= 0;
  }, [selectedPosition])

  useEffect(() => {
    getPositions();
    getCompetion();
    getSeason(2);
    getCountry();
  }, []);

  const getPositions = async () => {
    try {
      const response = await $apiV1.get(`/platform/position/`, {
        params: {
          search: text,
        },
      });
      // hide Substitute player value
      setPositions(response.data.results.filter(item => item.id !== 23));
    } catch (error) {
      console.error("Error fetching players:", error);
    } finally {
    }
  };

  const getCountry = async () => {
    try {
      const response = await $apiV1.get(`/platform/nationality/`, {
        params: {
          search: countryName,
        },
      });
      setCountry(response.data.results);
    } catch (error) {
      console.error("Error fetching players:", error);
    } finally {
    }
  };

  const getSeason = async (page = 1) => {
    try {
      const response = await $apiV1.get(`/platform/year_season/`, { params: { page, search: seasonName } });
      setSelectSeason(prev => ([...prev, ...response.data.results]));
      setPageSeason(page);
    } catch (error) {
      console.error("Error fetching players:", error);
    } finally {
    }
  };

  const getCompetion = async () => {
    try {
      const response = await $apiV1.get(`/platform/competition/`, {
        params: {
          search: competitionName,
        },
      });
      setCompetition(response.data.results);
    } catch (error) {
      console.error("Error fetching players:", error);
    } finally {
    }
  };

  useEffect(() => {
    if (text) {
      getPositions();
    }

    if (countryName) {
      getCountry();
    }

    if (competitionName) {
      getCompetion();
    }

    if (seasonName) {
      getSeason();
    }
  }, [text, countryName, competitionName, seasonName]);

  const handleSearch = async (page) => {
    try {
      setLoadingSearch(true);
      let params;
      if (isGkPosSelected) {
        params = {
          params: Object.values(displayedsParamsGK).join(),
          page: page,
          year_season: selectedSeason?.map(item => item.id)?.join(','),
          gender: selectedGender,
          search: search,
          age_older: olderAge,
          age_under: underAge,
          team_type: selectedTeamType,
          leg: selectedLeg,
          position: selectedPosition?.map(item => item.id)?.join(','),
          country: selectedContry?.map(item => item.id)?.join(','),
          competition: selectedCompetition?.map(item => item.id)?.join(','),
          height_less: heightFrom,
          height_larger: heightTo,
          mins_played_less: minPlayedFrom,
          mins_played_larger: minPlayedTo,
          aerial_duels_larger: aerialDuelsWonFrom,
          aerial_duels_less: aerialDuelsWonTo,
          effective_saves_larger: effectiveSavesFrom,
          effective_saves_less: effectiveSavesTo,
          clean_sheets_larger: cleanSheetsFrom,
          clean_sheets_less: cleanSheetsTo,
        }
      } else {
        params = {
          params: Object.values(displayedParamsFielder).join(),
          page: page,
          year_season: selectedSeason?.map(item => item.id)?.join(','),
          gender: selectedGender,
          search: search,
          age_older: olderAge,
          age_under: underAge,
          team_type: selectedTeamType,
          leg: selectedLeg,
          position: selectedPosition?.map(item => item.id)?.join(','),
          country: selectedContry?.map(item => item.id)?.join(','),
          competition: selectedCompetition?.map(item => item.id)?.join(','),
          height_less: heightFrom,
          height_larger: heightTo,
          mins_played_less: minPlayedFrom,
          mins_played_larger: minPlayedTo,
          goals_less: goalsTo,
          goals_larger: goalsFrom,
          assists_less: assistFrom,
          assists_larger: assistTo,
          passes_larger: passAccuracyFrom,
          passes_less: passAccuracyTo,
          duels_larger: duelsWonFrom,
          duels_less: duelsWonTo,
          aerial_duels_larger: aerialDuelsWonFrom,
          aerial_duels_less: aerialDuelsWonTo,
          crosses_larger: crossesFrom,
          crosses_less: crossesTo,
          passes_interception_larger: interceptionFrom,
          passes_interception_less: interceptionTo,
          ball_recoveries_larger: ballRecoveriesFrom,
          ball_recoveries_less: ballRecoveriesTo,
          dribblings_larger: dribblingsFrom,
          dribblings_less: dribblingsTo,
          tackles_larger: tacklesFrom,
          tackles_less: tacklesTo,
          shots_larger: shotsFrom,
          shots_less: shotsTo,
        }
      }
      const response = await $apiV2.get(`/platform/players/?`, { params });
      setPlayerSearchResults(response.data.results);
      setTotalPlayers(response?.data?.count);
    } catch (error) {
      console.error("Error fetching players:", error);
    } finally {
      setLoadingSearch(false);
    }
  };

  useEffect(() => {
    handleSearch(1);
  }, [])

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= Math.ceil(totalPlayers / playersPerPage)) {
      setPage(newPage);
      handleSearch(newPage);
    }
  };

  const genderOptions = [
    { id: "M", name: "Male" },
    { id: "F", name: "Female" },
  ];

  const legOptions = [
    { id: "R", name: "Right" },
    { id: "L", name: "Left" },
    { id: "RL", name: "Both" },
  ];

  const teamTypeOptions = [
    { id: "1", name: "Club" },
    { id: "2", name: "National" },
  ];

  const clearFilter = () => {
    setSearch("");
    setOlderAge("");
    setUnderAge("");
    setHeightFrom("");
    setHeightTo("");
    setMinPlayerdFrom("");
    setMinPlayedTo("");
    setGoalsFrom("");
    setGoalsTo("");
    setGoalsFrom(""); // Assuming this is a typo, remove duplicate line if unnecessary
    setAssistFrom("");
    setAssistTo("");
    setPassAccuracyFrom("");
    setPassAccuracyTo("");
    setDuelsWonFrom("");
    setDuelsWonTo("");
    setCrossesFrom("");
    setCrossesTo("");
    setInterceptionFrom("");
    setInterceptionTo("");
    setAerialDuelsWonFrom("");
    setAerialDuelsWonTo("");
    setBallRecoveriesFrom("");
    setBallRecoveriesTo("");
    setDribblingsFrom("");
    setDribblingsTo("");
    setTacklesFrom("");
    setTacklesTo("");
    setShotsFrom("");
    setShotsTo("");
    setCleanSheetsFrom("");
    setCleanSheetsTo("");

    setLegName("");
    setSelectedLeg("");

    setGenderName("");
    setSelectedGender("");

    setCompetitionName("");
    setSelectedCompetition([]);

    setSelectedSeason([]);
    setSeasonName("");

    setTeamTypeName("");
    setSelectedTeamType("");

    setCountryName("");
    setSelectedCountry([]);

    setText("");
    setSelectedPosition([]);

    setPlayerSearchResults([]);
    setTotalPlayers(0);
  };



  const [navigationOpen, setNavigationOpen] = useState(
    localStorage.getItem("navigationOpen")
  );

  useEffect(() => {
    const handleNavigationOpenChange = () => {
      setNavigationOpen(localStorage.getItem("navigationOpen"));
      console.log(localStorage.getItem("navigationOpen"), "ss");
    };

    window.addEventListener(
      "navigationOpenChange",
      handleNavigationOpenChange
    );

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener(
        "navigationOpenChange",
        handleNavigationOpenChange
      );
    };
  }, []);

  const handleCheckCompetition = (e, item) => {
    let selectedCompetitions = [...selectedCompetition];
    if (e.target.checked) {
      selectedCompetitions = [...selectedCompetitions, item];
    } else {
      selectedCompetitions = selectedCompetitions.filter(comp => comp.id !== item.id);
    }
    setSelectedCompetition(selectedCompetitions);
  }

  const handleCheckSeason = (e, item) => {
    let selectedSeasons = [...selectedSeason];
    if (e.target.checked) {
      selectedSeasons = [...selectedSeasons, item];
    } else {
      selectedSeasons = selectedSeasons.filter(season => season.id !== item.id);
    }
    setSelectedSeason(selectedSeasons)
  }

  const handleCheckPositions = (e, item) => {
    let selectedPositions = [...selectedPosition];
    if (e.target.checked) {
      selectedPositions = [...selectedPositions, item];
    } else {
      selectedPositions = selectedPositions.filter(pos => pos.id !== item.id);
    }
    setSelectedPosition(selectedPositions);
  }

  const handleCheckCountry = (e, item) => {
    let selectedCountries = [...selectedContry];
    if (e.target.checked) {
      selectedCountries = [...selectedCountries, item];
    } else {
      selectedCountries = selectedCountries.filter(comp => comp.id !== item.id);
    }
    setSelectedCountry(selectedCountries);
  }

  return (
    <MainWrapper>
      <Navigation />
      <MainWrapperFavorite
        className={
          localStorage.getItem("navigationOpen") === "true"
            ? ""
            : "fullWidthPage"
        }
      >
        <TopWrapper>
          <IntroText
            smallTitle={t("smrtSearch.smallTitle")}
            title={t("smrtSearch.title")}
          />
          <IntroTopRight>
            <IntroTopSearch>
              {isMobile ? (
                <Button
                  onClick={() => navigate(`/compare`)}
                  title={t("smrtSearch.button")}
                  sizeResponse={"full"}
                  style={{ zIndex: 0 }}
                />
              ) : (
                <CompanreButton onClick={() => navigate(`/compare`)}>
                  {t("smrtSearch.button")}
                </CompanreButton>
              )}
            </IntroTopSearch>
          </IntroTopRight>
        </TopWrapper>

        <button
          className="buttonFilter"
          onClick={() => setOpenFilter(!openFilter)}
        >
          {t("smrtSearch.filter")}
        </button>

        <Filter
          openFilter={openFilter}
          setOpenFilter={setOpenFilter}
          style={{ zIndex: "1", position: "relatives" }}
          onClick={() => clearFilter()}
        >
          <FilterContentWrap>
            <InputWrapper>
              <input
                type="text"
                // value={competitionName}
                value={competitionName}
                placeholder="Type Competition name"
                onClick={() => setOpenCompetition(!openCompetition)}
                onChange={(e) => {
                  setCompetitionName(e.target.value);
                  if (e.target.value) {
                    setOpenCompetition(true);
                  } else {
                    setOpenCompetition(false);
                  }
                }}
              />
              <span>
                {selectedCompetition?.length > 0
                  ? `${selectedCompetition
                      ?.map((item) => item.name)
                      ?.join(", ")}`
                  : ""}
              </span>
              <img
                className="dropdownIcon"
                src={openCompetition ? arrowIconUp : arrowIconDown}
                alt={openCompetition ? "Collapse" : "Expand"}
                onClick={() => setOpenCompetition(!openCompetition)}
              />
            </InputWrapper>
            <div style={{ position: "relative" }}>
              {openCompetition && (
                <OpenedDropdown style={{ margin: 0 }}>
                  {competition.map((item, index) => {
                    return (
                      <ParamCheckboxStyle key={item.id}>
                        <input
                          type="checkbox"
                          value={item.id}
                          checked={selectedCompetition
                            ?.map((comp) => comp.id)
                            .includes(item.id)}
                          onChange={(e) => {
                            console.log("change", item);
                            handleCheckCompetition(e, item);
                          }}
                        />
                        <label key={index} style={{ color: "#fff" }}>
                          {item.name}
                        </label>
                      </ParamCheckboxStyle>
                    );
                  })}
                </OpenedDropdown>
              )}
            </div>
            <br />
            <InputWrapper>
              <input
                type="text"
                value={seasonName}
                placeholder={"Type Season"}
                onClick={() => setOpenSeason(!openSeason)}
                onChange={(e) => {
                  setSeasonName(e.target.value);
                  if (e.target.value) {
                    setOpenSeason(true);
                  } else {
                    setOpenSeason(false);
                  }
                }}
              />
              <span>
                {selectedSeason?.length > 0
                  ? `${selectedSeason?.map((item) => item.years)?.join(", ")}`
                  : ""}
              </span>
              <img
                className="dropdownIcon"
                src={openSeason ? arrowIconUp : arrowIconDown}
                alt={openSeason ? "Collapse" : "Expand"}
                onClick={() => setOpenSeason(!openSeason)}
              />
            </InputWrapper>
            <div style={{ position: "relative" }}>
              {openSeason && (
                <OpenedDropdown>
                  {selectSeason?.map((item) => {
                    return (
                      <ParamCheckboxStyle key={item.id}>
                        <input
                          type="checkbox"
                          checked={selectedSeason
                            ?.map((season) => season.id)
                            .includes(item.id)}
                          value={item.id}
                          onClick={(e) => handleCheckSeason(e, item)}
                        />
                        <label>{item.years}</label>
                      </ParamCheckboxStyle>
                    );
                  })}
                  {
                    <Button
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: "0 auto",
                      }}
                      title="Load more"
                      onClick={(e) => {
                        e.stopPropagation();
                        getSeason(pageSeason + 1);
                      }}
                    />
                  }
                </OpenedDropdown>
              )}
            </div>
            <br />
            <InputWrapper>
              <input
                type="text"
                value={search}
                placeholder="Type Name"
                onChange={(e) => setSearch(e.target.value)}
              />
            </InputWrapper>
            <br />
            <InputWrapper onClick={() => setOpenPositions(!openPositions)}>
              <input
                type="text"
                value={text}
                placeholder="Type Position"
                onChange={(e) => {
                  setText(e.target.value);
                  if (e.target.value) {
                    setOpenPositions(true);
                  } else {
                    setOpenPositions(false);
                  }
                }}
              />
              <span>
                {selectedPosition?.length > 0
                  ? `${selectedPosition?.map((item) => item.name)?.join(", ")}`
                  : ""}
              </span>
              <img
                className="dropdownIcon"
                src={openPositions ? arrowIconUp : arrowIconDown}
                alt={openPositions ? "Collapse" : "Expand"}
              />
            </InputWrapper>
            <div style={{ position: "relative" }}>
              {openPositions && (
                <OpenedDropdown style={{ margin: 0 }}>
                  {positions
                    .filter((item) => item.lexic_id !== null)
                    .map((item, index) => {
                      return (
                        <ParamCheckboxStyle key={item.id}>
                          <input
                            type="checkbox"
                            checked={selectedPosition
                              ?.map((pos) => pos.id)
                              ?.includes(item.id)}
                            value={item.id}
                            onChange={(e) => {
                              handleCheckPositions(e, item);
                            }}
                          />
                          <label style={{ color: "#fff" }}>{item.name}</label>
                        </ParamCheckboxStyle>
                      );
                    })}
                </OpenedDropdown>
              )}
            </div>
            <br />
            <InputWrapper>
              <input
                type="text"
                value={countryName}
                placeholder="Type Country name"
                onClick={() => setOpenCountry(!openCountry)}
                onChange={(e) => {
                  setCountryName(e.target.value);
                  if (e.target.value) {
                    setOpenCountry(true);
                  } else {
                    setOpenCountry(false);
                  }
                }}
              />
              <span>
                {selectedContry?.length > 0
                  ? `${selectedContry?.map((item) => item.name)?.join(", ")}`
                  : ""}
              </span>
              <img
                className="dropdownIcon"
                src={openCountry ? arrowIconUp : arrowIconDown}
                alt={openCountry ? "Collapse" : "Expand"}
                onClick={() => setOpenCountry(!openCountry)}
              />
            </InputWrapper>
            <div style={{ position: "relative" }}>
              {openCountry && (
                <OpenedDropdown style={{ margin: 0 }}>
                  {country
                    .filter((item) => item.flag !== null)
                    .map((item, index) => {
                      return (
                        <ParamCheckboxStyle key={item.id}>
                          <input
                            type="checkbox"
                            value={item.id}
                            checked={selectedContry
                              ?.map((country) => country.id)
                              ?.includes(item.id)}
                            onChange={(e) => {
                              handleCheckCountry(e, item);
                            }}
                          />
                          <label key={index} style={{ color: "#fff" }}>
                            {item.name}
                          </label>
                        </ParamCheckboxStyle>
                      );
                    })}
                </OpenedDropdown>
              )}
            </div>
            <br />
            <InputWrapper>
              <input
                type="text"
                value={teamTypeName}
                placeholder="Select Team Type"
                readOnly
                onClick={() => setOpenTeamType(!openTeamType)}
              />
              <img
                className="dropdownIcon"
                src={openTeamType ? arrowIconUp : arrowIconDown}
                alt={openTeamType ? "Collapse" : "Expand"}
                onClick={() => setOpenTeamType(!openTeamType)}
              />
              <div style={{ position: "relative" }}>
                {openTeamType && (
                  <OpenedDropdown>
                    {teamTypeOptions.map((item) => (
                      <ParamCheckboxStyle key={item.id}>
                        <input
                          type="checkbox"
                          value={item.id}
                          checked={selectedTeamType === item.id}
                          onChange={(e) => {
                            const selectedId = e.target.value;
                            setSelectedTeamType(selectedId);
                            setTeamTypeName(
                              teamTypeOptions.find(
                                (option) => option.id === selectedId
                              )?.name || "Select Team Type"
                            );
                            setOpenTeamType(false);
                          }}
                        />
                        <label style={{ color: "#fff" }}>{item.name}</label>
                      </ParamCheckboxStyle>
                    ))}
                  </OpenedDropdown>
                )}
              </div>
            </InputWrapper>
            <br />
            <InputWrapper>
              <input
                type="text"
                value={genderName}
                placeholder="Select Gender"
                readOnly
                onClick={() => setOpenGender(!openGender)}
              />
              <img
                className="dropdownIcon"
                src={openGender ? arrowIconUp : arrowIconDown}
                alt={openGender ? "Collapse" : "Expand"}
                onClick={() => setOpenGender(!openGender)}
              />
              <div style={{ position: "relative" }}>
                {openGender && (
                  <OpenedDropdown>
                    {genderOptions.map((item) => (
                      <ParamCheckboxStyle key={item.id}>
                        <input
                          type="checkbox"
                          value={item.id}
                          checked={selectedGender === item.id}
                          onChange={(e) => {
                            const selectedId = e.target.value;
                            setSelectedGender(selectedId);
                            setGenderName(
                              genderOptions.find(
                                (option) => option.id === selectedId
                              )?.name || "Select Gender"
                            );
                            setOpenGender(false); // Close dropdown after selection
                          }}
                        />
                        <label style={{ color: "#fff" }}>{item.name}</label>
                      </ParamCheckboxStyle>
                    ))}
                  </OpenedDropdown>
                )}
              </div>
            </InputWrapper>
            <br />
            <InputWrapper>
              <input
                type="text"
                value={legName}
                placeholder="Select Leg"
                readOnly
                onClick={() => setOpenLeg(!openLeg)}
              />
              <img
                className="dropdownIcon"
                src={openLeg ? arrowIconUp : arrowIconDown}
                alt={openLeg ? "Collapse" : "Expand"}
                onClick={() => setOpenLeg(!openLeg)}
              />
              <div style={{ position: "relative" }}>
                {openLeg && (
                  <OpenedDropdown>
                    {legOptions.map((item) => (
                      <ParamCheckboxStyle key={item.id}>
                        <input
                          type="checkbox"
                          value={item.id}
                          checked={selectedLeg === item.id}
                          onChange={(e) => {
                            const selectedId = e.target.value;
                            setSelectedLeg(selectedId);
                            setLegName(
                              legOptions.find(
                                (option) => option.id === selectedId
                              )?.name || "Select Leg"
                            );
                            setOpenLeg(false);
                          }}
                        />
                        <label style={{ color: "#fff" }}>{item.name}</label>
                      </ParamCheckboxStyle>
                    ))}
                  </OpenedDropdown>
                )}
              </div>
            </InputWrapper>
            <br />
            <AgeWrapper>
              <InputWrapper>
                <input
                  type="number"
                  placeholder="Age From"
                  value={olderAge}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value >= 0) {
                      setOlderAge(value);
                    }
                  }}
                />
              </InputWrapper>

              <InputWrapper>
                <input
                  type="number"
                  placeholder="Age To"
                  value={underAge}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value >= 0) {
                      setUnderAge(value);
                    }
                  }}
                />
              </InputWrapper>
            </AgeWrapper>
            <br />
            <AgeWrapper>
              <InputWrapper>
                <input
                  type="number"
                  placeholder="Height From (cm)"
                  value={heightFrom}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value >= 0) {
                      setHeightFrom(value);
                    }
                  }}
                />
              </InputWrapper>

              <InputWrapper>
                <input
                  type="number"
                  placeholder="Height To (cm)"
                  value={heightTo}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value >= 0) {
                      setHeightTo(value);
                    }
                  }}
                />
              </InputWrapper>
            </AgeWrapper>
            <br />
            <h4 style={{ color: "#FFF" }}>
              Select Statistics (season/match avg.)
            </h4>
            <AgeWrapper>
              <InputWrapper>
                <input
                  min={0}
                  max={120}
                  type="number"
                  placeholder="Minutes (From)"
                  value={minPlayedTo}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value >= 0) {
                      setMinPlayedTo(value);
                    }
                  }}
                />
              </InputWrapper>

              <InputWrapper>
                <input
                  min={0}
                  max={120}
                  type="number"
                  placeholder="Minutes (To)"
                  value={minPlayedFrom}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value >= 0) {
                      setMinPlayerdFrom(value);
                    }
                  }}
                />
              </InputWrapper>
            </AgeWrapper>

            <br />
            {isGkPosSelected && (
              <>
                <AgeWrapper>
                  <InputWrapper>
                    <input
                      type="number"
                      placeholder="Effective Saves (From)"
                      value={effectiveSavesFrom}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value >= 0) {
                          setEffectiveSavesFrom(value);
                        }
                      }}
                    />
                  </InputWrapper>

                  <InputWrapper>
                    <input
                      type="number"
                      placeholder="Effective Saves (To)"
                      value={effectiveSavesTo}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value >= 0) {
                          setEffectiveSavesTo(value);
                        }
                      }}
                    />
                  </InputWrapper>
                </AgeWrapper>
                <br />
                <AgeWrapper>
                  <InputWrapper>
                    <input
                      type="number"
                      placeholder="Clean Sheets (From)"
                      value={cleanSheetsFrom}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value >= 0) {
                          setCleanSheetsFrom(value);
                        }
                      }}
                    />
                  </InputWrapper>

                  <InputWrapper>
                    <input
                      type="number"
                      placeholder="Clean Sheets (To)"
                      value={cleanSheetsTo}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value >= 0) {
                          setCleanSheetsTo(value);
                        }
                      }}
                    />
                  </InputWrapper>
                </AgeWrapper>
                <br />
              </>
            )}
            {!isGkPosSelected && (
              <>
                <AgeWrapper>
                  <InputWrapper>
                    <input
                      type="number"
                      placeholder="Goals (From)"
                      value={goalsFrom}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value >= 0) {
                          setGoalsFrom(value);
                        }
                      }}
                    />
                  </InputWrapper>

                  <InputWrapper>
                    <input
                      type="number"
                      placeholder="Goals (To)"
                      value={goalsTo}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value >= 0) {
                          setGoalsTo(value);
                        }
                      }}
                    />
                  </InputWrapper>
                </AgeWrapper>
                <br />
                <AgeWrapper>
                  <InputWrapper>
                    <input
                      type="number"
                      placeholder="Assist (From)"
                      value={assistTo}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value >= 0) {
                          setAssistTo(value);
                        }
                      }}
                    />
                  </InputWrapper>

                  <InputWrapper>
                    <input
                      type="number"
                      placeholder="Assist (To)"
                      value={assistFrom}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value >= 0) {
                          setAssistFrom(value);
                        }
                      }}
                    />
                  </InputWrapper>
                </AgeWrapper>
                <br />
                <AgeWrapper>
                  <InputWrapper>
                    <input
                      min={0}
                      type="number"
                      placeholder="Crosses (From)"
                      value={crossesFrom}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value >= 0) {
                          setCrossesFrom(value);
                        }
                      }}
                    />
                  </InputWrapper>

                  <InputWrapper>
                    <input
                      min={0}
                      type="number"
                      placeholder="Crosses (To)"
                      value={crossesTo}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value >= 0) {
                          setCrossesTo(value);
                        }
                      }}
                    />
                  </InputWrapper>
                </AgeWrapper>
                <br />
                <AgeWrapper>
                  <InputWrapper>
                    <input
                      min={0}
                      type="number"
                      placeholder="Interception + (From)"
                      value={interceptionFrom}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value >= 0) {
                          setInterceptionFrom(value);
                        }
                      }}
                    />
                  </InputWrapper>

                  <InputWrapper>
                    <input
                      min={0}
                      type="number"
                      placeholder="Interception + (To)"
                      value={interceptionTo}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value >= 0) {
                          setInterceptionTo(value);
                        }
                      }}
                    />
                  </InputWrapper>
                </AgeWrapper>
                <br />
                <AgeWrapper>
                  <InputWrapper>
                    <input
                      min={0}
                      type="number"
                      placeholder="Ball Recoveries (From)"
                      value={ballRecoveriesFrom}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value >= 0) {
                          setBallRecoveriesFrom(value);
                        }
                      }}
                    />
                  </InputWrapper>

                  <InputWrapper>
                    <input
                      min={0}
                      type="number"
                      placeholder="Ball Recoveries (To)"
                      value={ballRecoveriesTo}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value >= 0) {
                          setBallRecoveriesTo(value);
                        }
                      }}
                    />
                  </InputWrapper>
                </AgeWrapper>
                <br />
                <AgeWrapper>
                  <InputWrapper>
                    <input
                      min={0}
                      type="number"
                      placeholder="Tackles (From)"
                      value={tacklesFrom}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value >= 0) {
                          setTacklesFrom(value);
                        }
                      }}
                    />
                  </InputWrapper>

                  <InputWrapper>
                    <input
                      min={0}
                      type="number"
                      placeholder="Tackles (To)"
                      value={tacklesTo}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value >= 0) {
                          setTacklesTo(value);
                        }
                      }}
                    />
                  </InputWrapper>
                </AgeWrapper>
                <br />
              </>
            )}

            <h4 style={{ color: "#FFF" }}>Select Statistics (Season avg.)</h4>
            {!isGkPosSelected && (
              <>
                <AgeWrapper>
                  <InputWrapper>
                    <input
                      min={0}
                      max={100}
                      type="number"
                      placeholder="Pass accuracy (in %) - (From)"
                      value={passAccuracyFrom}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value >= 0) {
                          setPassAccuracyFrom(value);
                        }
                      }}
                    />
                  </InputWrapper>

                  <InputWrapper>
                    <input
                      min={0}
                      max={100}
                      type="number"
                      placeholder="Pass accuracy (in %) - (To)"
                      value={passAccuracyTo}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value >= 0) {
                          setPassAccuracyTo(value);
                        }
                      }}
                    />
                  </InputWrapper>
                </AgeWrapper>
                <br />
                <AgeWrapper>
                  <InputWrapper>
                    <input
                      min={0}
                      max={100}
                      type="number"
                      placeholder="Duels + (in %) - (From)"
                      value={duelsWonFrom}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value >= 0) {
                          setDuelsWonFrom(value);
                        }
                      }}
                    />
                  </InputWrapper>

                  <InputWrapper>
                    <input
                      min={0}
                      max={100}
                      type="number"
                      placeholder="Duels + (in %) - (To)"
                      value={duelsWonTo}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value >= 0) {
                          setDuelsWonTo(value);
                        }
                      }}
                    />
                  </InputWrapper>
                </AgeWrapper>
                <br />
                <AgeWrapper>
                  <InputWrapper>
                    <input
                      min={0}
                      max={100}
                      type="number"
                      placeholder="Dribblings + (in %) - (From)"
                      value={dribblingsFrom}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value >= 0) {
                          setDribblingsFrom(value);
                        }
                      }}
                    />
                  </InputWrapper>

                  <InputWrapper>
                    <input
                      min={0}
                      max={100}
                      type="number"
                      placeholder="Dribblings + (in %) - (To)"
                      value={dribblingsTo}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value >= 0) {
                          setDribblingsTo(value);
                        }
                      }}
                    />
                  </InputWrapper>
                </AgeWrapper>
                <br />
                <AgeWrapper>
                  <InputWrapper>
                    <input
                      min={0}
                      max={100}
                      type="number"
                      placeholder="Shots + (in %) - (From)"
                      value={shotsFrom}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value >= 0) {
                          setShotsFrom(value);
                        }
                      }}
                    />
                  </InputWrapper>

                  <InputWrapper>
                    <input
                      min={0}
                      max={100}
                      type="number"
                      placeholder="Shots + (in %) - (To)"
                      value={shotsTo}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value >= 0) {
                          setShotsTo(value);
                        }
                      }}
                    />
                  </InputWrapper>
                </AgeWrapper>
                <br />
              </>
            )}

            <AgeWrapper>
              <InputWrapper>
                <input
                  min={0}
                  max={100}
                  type="number"
                  placeholder="Aerial Duels + (in %) - (From)"
                  value={aerialDuelsWonFrom}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value >= 0) {
                      setAerialDuelsWonFrom(value);
                    }
                  }}
                />
              </InputWrapper>

              <InputWrapper>
                <input
                  min={0}
                  max={100}
                  type="number"
                  placeholder="Aerial Duels + (in %) - (To)"
                  value={aerialDuelsWonTo}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value >= 0) {
                      setAerialDuelsWonTo(value);
                    }
                  }}
                />
              </InputWrapper>
            </AgeWrapper>
            <br />
          </FilterContentWrap>
          <Button
            disabled={loadingSearch}
            size={"full"}
            onClick={() => {
              setPage(1);
              handleSearch(1);
            }}
            title={loadingSearch ? "Loading..." : "search"}
          />
        </Filter>

        {playerSearchResults.length > 0 ? (
          <SmrtSearchSidebar
            table={playerSearchResults}
            page={page}
            totalPages={Math.ceil(totalPlayers / playersPerPage)}
            loadingSearch={loadingSearch}
            onPageChange={handlePageChange}
            isGkPosSelected={isGkPosSelected}
          />
        ) : (
          <EmptyState>
            <img src={emptySearch} alt={emptySearch} />
            <h3>No results for players</h3>
          </EmptyState>
        )}
      </MainWrapperFavorite>
    </MainWrapper>
  );
};

export default Index;
